import React, { useState } from 'react';
import { HiOutlineArrowUp } from 'react-icons/hi';
import styled from 'styled-components';
const Button = styled.div`
    position: fixed;
    right: 15px;
    bottom: 15px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
    background-color: #00000024;
    color: var(--text-color);
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
`

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            {visible && <Button onClick={scrollToTop}>
                <HiOutlineArrowUp />
            </Button>}
        </>
    );
}

export default ScrollButton;