import moment from "moment";
import { Displayname, Pfp, Username, Userwrapper } from "../../../styles/components/posts/Post";
import { getPfp } from "../../../utils/constants";
import { OtherUser, Post } from "../../../utils/types"
import { useNavigate } from "react-router";
import { FlagsRenderer } from "../../root/FlagsRenderer";
import 'tippy.js/themes/light.css';
import { tippy } from "@tippyjs/react";

type Props = {
	user: OtherUser;
	post: Post;
}
export const PostUsername = ({ user, post }: Props) => {

	const navigate = useNavigate();

	tippy('[data-tippy-content]', { theme: "light-border" });
	return (
		<Userwrapper onClick={() => navigate(`/@${user.username}`)}>
				<Pfp src={getPfp(user.display_name, user.pfp)} alt="" />

				<div>
					<Displayname>{user.display_name} <FlagsRenderer flags={user.flags} /></Displayname>
					<Username>@{user.username} • <span data-tippy-content={moment(post.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}>{moment(post.date).fromNow()}</span></Username>
				</div>
			</Userwrapper>
	)
}