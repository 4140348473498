import { SetStateAction, createContext } from "react";
import { OtherUser, PostWithAuthor, User } from "./types";

type ContextType = {
    user: User | null;
    setUser: SetStateAction<any>;
    loading: boolean;
    recommendedUsers: OtherUser[];
    posts: PostWithAuthor[];
    setPosts: SetStateAction<any>;
    loadingPosts: boolean;
    recommendedPosts: PostWithAuthor[];
    trendingPosts: PostWithAuthor[];

    topPosts: PostWithAuthor[];
    setTopPosts: SetStateAction<any>;
    loadingTopPosts: boolean;
    setLoadingTopPosts: SetStateAction<any>;

    followingPosts: PostWithAuthor[];
    setFollowingPosts: SetStateAction<any>;
    loadingFollowingPosts: boolean;
    setLoadingFollowingPosts: SetStateAction<any>;

    bookmarks: string[],
    setBookmarks: SetStateAction<any>;

    notifications: any[];
    setNotifications: SetStateAction<any>;
    loadingNotifications: boolean, 
    setLoadingNotifications: SetStateAction<any>;
    loadedAllNotifications: boolean;
    setLoadedAllNotifications: SetStateAction<any>;
};

//@ts-ignore
export const Context = createContext<ContextType>();