import { useContext, useState } from "react";
import { EditingButton, EditingTextarea, PostBox } from "../../../styles/components/posts/Post";
import { PostWithAuthor } from "../../../utils/types";
import { PostUsername } from "./Username";
import { PostAttachments } from "./Attachments";
import { resize } from "../../../utils/constants";
import { Context } from "../../../utils/Context";
import { toast } from "react-toastify";
import { editPost } from "../../../api/POST";

type Props = {
	post: PostWithAuthor;
	setEditing: (e: boolean) => void;
}
export const EditingPost = ({ post, setEditing }: Props) => {

	const { user, posts, setPosts, topPosts, setTopPosts, followingPosts, setFollowingPosts } = useContext(Context);

	const [content, setContent] = useState(post.post.content);
	const [removeAttachments, setRemoveAttachments] = useState(false);

	const [saving, setSaving] = useState(false);
	const editPostFunction = () => {
		if (saving) return;
		if (!user) return toast.error("You are not logged in.");

		setSaving(true);
		toast.promise(
			editPost(post.post.postId, content, removeAttachments)
				.then(({ data }) => {
					if (posts[posts.findIndex((p) => p.post.postId === post.post.postId)]) posts[posts.findIndex((p) => p.post.postId === post.post.postId)].post.content = data.content;
					if (posts[posts.findIndex((p) => p.post.postId === post.post.postId)]) posts[posts.findIndex((p) => p.post.postId === post.post.postId)].post.edited = data.edited;
					setPosts([...posts]);

					if (followingPosts[followingPosts.findIndex((p) => p.post.postId === post.post.postId)]) followingPosts[followingPosts.findIndex((p) => p.post.postId === post.post.postId)].post.content = data.content;
					if (followingPosts[followingPosts.findIndex((p) => p.post.postId === post.post.postId)]) followingPosts[followingPosts.findIndex((p) => p.post.postId === post.post.postId)].post.edited = data.edited;
					setFollowingPosts([...followingPosts]);

					if (topPosts[topPosts.findIndex((p) => p.post.postId === post.post.postId)]) topPosts[topPosts.findIndex((p) => p.post.postId === post.post.postId)].post.content = data.content;
					if (topPosts[topPosts.findIndex((p) => p.post.postId === post.post.postId)]) topPosts[topPosts.findIndex((p) => p.post.postId === post.post.postId)].post.edited = data.edited;
					setTopPosts([...topPosts]);

					if (window.location.pathname === `/post/${post.post.postId}`) window.location.reload();
					setEditing(false);
				}),
			{
				pending: 'Saving...',
				success: 'Saved!',
				error: {
					render() {
						setSaving(false);
						setEditing(false);
						return 'Failed to save the post.'
					},
				}
			});
	}

	return (
		<PostBox>
			<PostUsername user={post.author} post={post.post} />

			<EditingTextarea value={content} onChange={(e) => {
				setContent(e.currentTarget.value);
				resize(e.currentTarget, true);
			}} />

			{!removeAttachments && <PostAttachments post={post.post} />}

			<EditingButton onClick={() => setEditing(false)}>Cancel</EditingButton>
			<EditingButton onClick={() => setRemoveAttachments(!removeAttachments)}>Remove Attachments</EditingButton>
			<EditingButton onClick={() => editPostFunction()}>Save</EditingButton>
		</PostBox>
	)
}