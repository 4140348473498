import { useNavigate } from "react-router";
import { Listbox, MenuButton, MenuItem } from "../../../styles/General";
import { DotsImg } from "../../../styles/components/posts/Post";
import { SITE_URL, copy } from "../../../utils/constants";
import { CommentWithAuthor, PostWithAuthor } from "../../../utils/types";
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { useContext, useState } from "react";
import { Context } from "../../../utils/Context";
import { HiOutlineClipboard, HiOutlinePencil, HiOutlineTrash, HiOutlineUserCircle } from "react-icons/hi2";
import TripleDots from "../../../assets/icons/tripple-dots.svg";
import { toast } from "react-toastify";
import { deleteComment } from "../../../api/COMMENT";
import { HiDotsHorizontal } from "react-icons/hi";

type Props = {
	comment: CommentWithAuthor;
	post: PostWithAuthor;
	setEditing: (editing: boolean) => void;
	comments: CommentWithAuthor[];
	setComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
	topComments: CommentWithAuthor[];
	setTopComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
	followingComments: CommentWithAuthor[];
	setFollowingComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
}
export const CommentDropdown = ({ comment, setEditing, post, setComments, comments, topComments, setTopComments, setFollowingComments, followingComments }: Props) => {

	const navigate = useNavigate();
	const { user } = useContext(Context);

	const [deleting, setDeleting] = useState(false);
	const deleteCommentFunction = () => {
		if (deleting) return;
		if (!user) return toast.error("You are not logged in.");

		setDeleting(true);
		deleteComment(comment.comment.postId, comment.comment.commentId)
			.then(() => {
				setComments(comments.filter((p) => p.comment.commentId !== comment.comment.commentId));
				setTopComments(topComments.filter((p) => p.comment.commentId !== comment.comment.commentId));
				setFollowingComments(followingComments.filter((p) => p.comment.commentId !== comment.comment.commentId));
				setDeleting(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.response.data.error);
				setDeleting(false);
			});
	}

	return (
		<Dropdown>
			<MenuButton><HiDotsHorizontal color="var(--text-color" /></MenuButton>

			<Menu slots={{ listbox: Listbox }}>
				<MenuItem onClick={() => navigate(`/@${comment.author.username}`)}><HiOutlineUserCircle /> Visit Profile</MenuItem>
				<MenuItem onClick={() => copy(`${SITE_URL}/post/${post.post.postId}`)}><HiOutlineClipboard /> Copy Link</MenuItem>

				{user?.userId === comment.author.userId && <MenuItem onClick={() => setEditing(true)}><HiOutlinePencil /> Edit</MenuItem>}
				{(user?.userId === comment.author.userId || user?.flags.includes("moderator")) &&
					<MenuItem onClick={() => deleteCommentFunction()} style={{ color: '#d11b1b' }}><HiOutlineTrash /> Delete</MenuItem>}
			</Menu>
		</Dropdown>
	)
}