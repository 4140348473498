import styled from "styled-components";

export const BackBar = styled.div`
    background-color: var(--navbar-color);
    display: flex;
    align-items: center;
    font-weight: 400;
	box-shadow: 0px 4px 4px 0px var(--box-shadow);
    font-size: 15px;
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 8px;

    svg { margin-right: 10px; }
`;

export const CommentsBox = styled.div`
    background: var(--navbar-color);
    border-radius: 0px 0px 8px 8px;
    padding: 15px 25px;
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
`;