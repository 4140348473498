import { AxiosRequestConfig } from "axios";

// @ts-ignore
import bcrypt from 'bcryptjs';
import { toast } from "react-toastify";
import { User } from "./types";

export const SITE_URL = process.env.REACT_APP_REST_ENV ? 'https://pasterboard.site' : 'http://localhost:3000';
export const API_URL = process.env.REACT_APP_REST_ENV ? 'https://api.pasterboard.site' : 'http://localhost:3001';
export const CDN_URL = process.env.REACT_APP_REST_ENV ? 'https://cdn.pasterboard.site' : 'http://localhost:3002';
export const WS_URL = process.env.REACT_APP_REST_ENV ? 'wss://api.pasterboard.site' : 'ws://localhost:3001';

export const CONFIG: AxiosRequestConfig = { withCredentials: true };
export const RequestHeader = {
    headers: {
        Authorization: document.cookie ? document.cookie.split(';').filter((s: string) => s.includes('token'))[0].split('=')[1] : ''
    },
    withCredentials: true
};


export const testEmail = (s: string | null) => {
    return (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g.test(s ?? ''));
}

export const testPassword = (s: string | null) => {
    return (/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(s ?? '')) && !((s || '').length < 8 || ((s || '').length > 100));
}

export async function hashPassword(rawPw: string) {
    const hash = await bcrypt.hash(rawPw, 10);
    return hash;
}

export const getPfp = (displayname: string, pfp?: string) => {
    return pfp ? `${CDN_URL}/${pfp}` : `https://ui-avatars.com/api/?name=${displayname}&size=100`;
}

export function copy(text: string) {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard!');
}

//* Resize textarea, -24 for padding adjustment
export function resize(e: any, doNotRemove?: boolean) {
    e.style.height = 0;
    e.style.height = e.scrollHeight + 'px';
}

export const typeTexts = (name: string) => {
    const texts: string[] = [
        `Hey ${name}, how are you doing?`,
        `What's up ${name}?`,
        `Hey ${name}, how's your day going?`,
        `What's on your mind, ${name}?`,
        `Start sharing your thoughts, ${name}!`,
        `How are you feeling today, ${name}?`,

    ]
    return texts[Math.floor(Math.random() * texts.length)];
}

export const loginErrors = [
    {
        error: "banned",
        message: "You have been banned from using Pasterboard."
    },
    {
        error: "google",
        message: "There was an error trying to login with google, please try again."
    },
    {
        error: "token",
        message: "Failed to generate a login token, please login again."
    },
    {
        error: "email-taken",
        message: "This email is already in use."
    },
    {
        error: "inc_method",
        message: "Incorrect login method, please login using your password."
    },
    {
        error: "save",
        message: "Failed to save the user, please try again."
    }
]

// Update the video and image when they are uploaded
export const handleAttachmentChange = (e: any, user: User, setAttachment: any, resetOne: any, resetTwo: any) => {
    if (!user?.verified) return toast.error("You need to verify your email to post with attachments. Check your email or go to the settings page to resend the verification email.");
    resetTwo({ preview: '', data: '' });
    resetOne({ preview: '', data: '' });
    setAttachment({
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
    });
}