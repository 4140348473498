import { HiXMark } from "react-icons/hi2";
import { Background, PopupClose, PopupElement, PopupTitle, PopupTitleText } from "../../styles/components/root/Popup";

type Props = {
	content: any;
	title: string;
	close: () => void;
}
export const Popup = ({ content, title, close }: Props) => {

	document.addEventListener('keydown', (e) => {
		if (e.key === 'Escape') close();
	});

	const popup = document.getElementById("popup") as HTMLDivElement;
	//* If the click that happens isn't on the creator, then un-focus the creator
	window.onclick = (e) => {
		console.log(popup)
			if (popup && e.target !== popup && !popup.contains(e.target as Node)) close();
	}

	return (
		<Background>
			<PopupElement id="popup">
				<PopupTitle>
					<PopupTitleText>{title}</PopupTitleText>

					<PopupClose onClick={close}><HiXMark color="#fff" size={20} /></PopupClose>
				</PopupTitle>

				{content}
			</PopupElement>
		</Background>
	)
}