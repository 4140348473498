import axios from "axios";
import { API_URL, RequestHeader } from "../utils/constants";


export const getRecommendedUsers = async () =>
    await axios.post(`${API_URL}/user/recommended-users`, {}, RequestHeader);

export const followUser = async (userId: string) =>
    await axios.post(`${API_URL}/user/follow`, { userId }, RequestHeader);

export const getUser = async (username: string) =>
    await axios.post(`${API_URL}/user/get`, { username }, RequestHeader);

export const updateUser = async (display_name: string, bio: string, website: string, location: string, username: string, pfp?: any) =>
    await axios.post(`${API_URL}/user/update`, { display_name, bio, website, location, username, pfp }, RequestHeader);

export const getUserPosts = async (username: string, date?: number) =>
    await axios.post(`${API_URL}/user/posts`, { username, date }, RequestHeader);

export const getFollowers = async (username: string, previousAmount: number) =>
    await axios.post(`${API_URL}/user/followers`, { username, previousAmount }, RequestHeader);

export const getFollowing = async (username: string, previousAmount: number) =>
    await axios.post(`${API_URL}/user/following`, { username, previousAmount }, RequestHeader);

export const sendVerificationEmail = async () =>
    await axios.post(`${API_URL}/user/send-verification`, {}, RequestHeader);

export const checkVerificationCode = async (code: string) =>
    await axios.post(`${API_URL}/user/check-verification`, { code }, RequestHeader);

export const updateEmailAdress = async (oldEmail: string, newEmail: string) =>
    await axios.post(`${API_URL}/user/update-email`, { oldEmail, newEmail }, RequestHeader);

export const updatePassword = async (oldPassword: string, newPassword: string) =>
    await axios.post(`${API_URL}/user/update-password`, { oldPassword, newPassword }, RequestHeader);

export const deleteAccount = async (password: string) =>
    await axios.post(`${API_URL}/user/delete-account`, { password }, RequestHeader);

export const searchUsers = async (query: string) =>
    await axios.post(`${API_URL}/user/search`, { query }, RequestHeader);

export const getUserNotifications = async (count?: number) =>
    await axios.post(`${API_URL}/user/notifications`, { count }, RequestHeader);