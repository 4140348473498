import axios from "axios";
import { API_URL, RequestHeader } from "../utils/constants";

export const createPost = async (content: string, repostId: string, image?: string, video?: string, attachment?: string) =>
    await axios.post(`${API_URL}/post/create`, { content, image, video, repostId, attachment }, RequestHeader);

export const getPosts = async (type: string) =>
    await axios.post(`${API_URL}/post/get`, { type }, RequestHeader);

export const getPost = async (postId: string) =>
    await axios.post(`${API_URL}/post/get-one`, { postId }, RequestHeader);

export const likePost = async (postId: string) =>
    await axios.post(`${API_URL}/post/like`, { postId }, RequestHeader);

export const getLikes = async (postId: string, previousAmount: number) =>
    await axios.post(`${API_URL}/post/likes`, { postId, previousAmount }, RequestHeader);

export const getMore = async (postCount: number, type: string) =>
    await axios.post(`${API_URL}/post/get-more`, { postCount, type }, RequestHeader);

export const deletePost = async (postId: string) =>
    await axios.post(`${API_URL}/post/delete`, { postId }, RequestHeader);

export const editPost = async (postId: string, content: string, deleteAttachments: boolean) =>
    await axios.post(`${API_URL}/post/edit`, { postId, content, deleteAttachments }, RequestHeader);


export const searchPosts = async (query: string) =>
    await axios.post(`${API_URL}/post/search`, { query }, RequestHeader);

export const searchMorePosts = async (query: string, date: number) =>
    await axios.post(`${API_URL}/post/search-more`, { query, date }, RequestHeader);

export const getRecommendedPosts = async () =>
    await axios.post(`${API_URL}/post/recommended-posts`, {}, RequestHeader);

export const getTrendingPosts = async () =>
    await axios.post(`${API_URL}/post/trending-posts`, {}, RequestHeader);

export const bookmarkPost = async (postId: string) =>
    await axios.post(`${API_URL}/post/bookmark`, { postId }, RequestHeader);

export const getBookmarks = async (previousCount: number) =>
    await axios.post(`${API_URL}/post/bookmarks`, { previousCount }, RequestHeader);