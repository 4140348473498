import styled from "styled-components";
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem } from '@mui/base/MenuItem';

export const ProfileBox = styled.div`
	background: var(--navbar-color);
	border-radius: 8px;
	box-shadow: 0px 4px 4px 0px var(--box-shadow);
	padding: 20px;
	margin-bottom: 10px;
	display: flex;
`;

export const Flex = styled.div`
	display: flex;
	align-items: center;

	@media screen and (max-width: 700px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const Pfp = styled.img`
	border-radius: 50%;
object-fit: cover;
	width: 100px;
	height: 100px;
	margin-right: 25px;
	object-fit: cover;
`;

export const Displayname = styled.b`
	font-size: 20px;
	margin: 0px;
	font-weight: 600;
	display: flex;
	align-items: center;

	img {
		margin-left: 10px;
	}
`;

export const Username = styled.p`
	font-size: 15px;
	margin: 0px;
	margin-top: 5px;
	color: #979797;
`;

export const ButtonFill = styled.a`
  background-color: white;
  color: var(--accent);
  padding: 6px 20px;
  margin: 0px;
  border: 1px solid var(--accent);
  border-radius: 20px;

  :hover {
      color: var(--accent-hover);
  }
`;

export const ButtonBorder = styled.a`
  color: white;
  padding: 6px 20px;
  margin: 0px;
  background-color: var(--accent);
  border-radius: 20px;

  :hover {
      color: #fff;
      background-color: var(--accent-hover);
  }
`;

export const FollowersText = styled.p`
	margin: 0px;
	color: #979797;
	font-size: 15px;
	margin-right: 6px;

	span {
		font-weight: 600;
		color: var(--text-color);
	}
`;

export const DotsImg = styled.img`
	cursor: pointer;
	height: fit-content;
`;

export const Listbox = styled('ul')`
	z-index: 0;
	box-sizing: border-box;
	margin: 5px 0;
	padding: 3px;
	font-family: 'Inter', sans-serif;
	min-width: 115px;
	border-radius: 8px;
	overflow: auto;
	font-weight: 500;
	outline: 0px;
	background: var(--background-color);
	color: var(--text-color);
	box-shadow: 0px 4px 4px 0px var(--background-color);
	z-index: 1;
`;

export const MenuItem = styled(BaseMenuItem)`
	list-style: none;
	padding: 10px;
	cursor: pointer;
	background-color: var(--background-color);
	border-radius: 5px;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 400;

	svg {
		margin-right: 10px;
	}

	&:last-child {
		border-bottom: none;
	}
  
	&:hover {
	  background: var(--navbar-color);
	}
`;

export const MenuButton = styled(BaseMenuButton)`
	background: none;
	border: none;
	height: fit-content;
	cursor: pointer;
`;


export const ProfilePictureUploader = styled.label`
    border-radius: 10px;
    margin-right: 5px;
    z-index: 10;
    cursor: pointer;
    text-align: center;
		background: #608AB5;
    color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 20px;
		width: fit-content;

    svg {
        color: white;
				margin-right: 10px;
    }
`;