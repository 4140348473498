import { useEffect, useState, useContext } from "react";
import { getPost } from "../api/POST";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";
import { Userbar } from "../components/sidebar/Userbar";
import { PostDisplay } from "../components/posts/Post";
import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { useParams } from "react-router";
import { CommentWithAuthor, PostWithAuthor } from "../utils/types";
import { BackBar, CommentsBox } from "../styles/pages/Post";
import { HiOutlineArrowLeft } from "react-icons/hi2";
import { CommentCreator } from "../components/comments/CommentCreator";
import { CommentDisplay } from "../components/comments/Comment";
import { getComments, getMoreComments } from "../api/COMMENT";
import { toast } from "react-toastify";
import { PostLoadingSkeleton } from "../components/loading/Post";
import { NotFound } from "./NotFound";
import { MoonLoader } from "react-spinners";
import { Loader } from "../components/root/RetryLoad";
import { Context } from "../utils/Context";
import styled from "styled-components";

const Box = styled.div`
    padding: 10px 20px;
    background: var(--navbar-color);
    display: flex;
    border-radius: 8px 8px 0px 0px;
    margin-top: 10px;
`;

const Button = styled.a<{ active: boolean }>`
    border: 0px solid white;
    text-decoration: none !important;
    transition: 0.1s;
    width: fit-content !important;
    color: rgb(134, 142, 150);
    padding: 6px 14px;
    margin: 0px;
    border-radius: 32px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    display: block;

    
    ${({ active }) => !active && `
        :hover {    
            color: rgb(134, 142, 150);
            background-color: var(--hover-color);
        }

        &:active {
            background-color: var(--active-color);
            color: white;
        }
    `}


    ${({ active }) => active && `
        background-color: rgb(134, 142, 150);
        color: white;

        
        :hover {
            color: #fff;
            cursor: default;
        }

        &:active {
            background-color: rgb(116, 123, 129);
        }
    `}

    &:active {
        transform: translateY(1px);
    }
`;

export const PostPage = () => {

    const { postId } = useParams();
    const [tab, setTab] = useState("latest");

    const { user } = useContext(Context);

    const [post, setPost] = useState<PostWithAuthor | null>(null);
    const [loadingPost, setLoadingPost] = useState(true);
    const [replyId, setReplyId] = useState<string>("");

    const [comments, setComments] = useState<CommentWithAuthor[]>([]);
    const [loadingComments, setLoadingComments] = useState(true);
    const [topComments, setTopComments] = useState<CommentWithAuthor[]>([]);
    const [loadingTopComments, setLoadingTopComments] = useState(false);
    const [followingComments, setFollowingComments] = useState<CommentWithAuthor[]>([]);
    const [loadingFollowingComments, setLoadingFollowingComments] = useState(false);
    const [loadedAll, setLoadedAll] = useState(false);

    useEffect(() => {
        getPost(postId ?? "")
            .then(({ data }) => {
                setPost(data);
                setLoadingPost(false);
            }).catch((err) => {
                console.log(err);
                setLoadingPost(false);
            });

        getComments(postId ?? "", "latest")
            .then(({ data }) => {
                setComments(data);
                setLoadingComments(false);

                if (data.length < 10) setLoadedAll(true);

            }).catch((err) => {
                console.log("There was an error loading the comments! " + err);
                toast.error("There was an error loading the comments! " + err);
                setLoadingComments(false);
            });
    }, [postId]);


    window.onscroll = function () { checkScroll(); };

    const [loadingMore, setLoadingMore] = useState(false);
    const checkScroll = async () => {

        const percentage = Math.floor(document.documentElement.scrollTop / (document.body.offsetHeight - window.innerHeight) * 100);

        if (percentage > 80 && comments && !loadingMore && !loadedAll) {
            setLoadingMore(true);

            if (tab === "latest") {
                const { data: newComments } = await getMoreComments(postId ?? "", comments.length, tab);
                if (!newComments) return setLoadingMore(false);
                if (!newComments[0]) {
                    setLoadingMore(false);
                    setLoadedAll(true);
                    return;
                }
                if (newComments[0].comment.commentId !== comments[comments.length - 1].comment.commentId) setComments(comments.concat(newComments));

                setLoadingMore(false);
            } else if (tab === "following") {
                const { data: newComments } = await getMoreComments(postId ?? "", followingComments.length, tab);
                if (!newComments) return setLoadingMore(false);
                if (!newComments[0]) {
                    setLoadingMore(false);
                    setLoadedAll(true);
                    return;
                }
                if (newComments[0].comment.commentId !== followingComments[followingComments.length - 1].comment.commentId) setFollowingComments(followingComments.concat(newComments));

                setLoadingMore(false);
            } else if (tab === "top") {
                const { data: newComments } = await getMoreComments(postId ?? "", topComments.length, tab);
                if (!newComments) return setLoadingMore(false);
                if (!newComments[0]) {
                    setLoadingMore(false);
                    setLoadedAll(true);
                    return;
                }
                if (newComments[0].comment.commentId !== topComments[topComments.length - 1].comment.commentId) setTopComments(topComments.concat(newComments));

                setLoadingMore(false);
            }
        }
    }

    const updateTab = (tab: string) => {
        if (loadingComments || loadingTopComments || loadingFollowingComments) return;
        setTab(tab);
    }

    useEffect(() => {
        setLoadedAll(false);
        setLoadingMore(false);

        if (tab === "top" && topComments.length === 0 && !loadingTopComments) {
            setLoadingTopComments(true);
            getComments(postId ?? "", "top")
                .then(({ data }) => {
                    setTopComments(data);
                    setLoadingTopComments(false);
                }).catch((err) => {
                    console.log("There was an error loading the comments!" + err);
                    toast.error("There was an error loading the comments!" + err);

                    setLoadingTopComments(false);
                });
        } else if (tab === "following" && followingComments.length === 0 && !loadingFollowingComments) {
            setLoadingFollowingComments(true);
            getComments(postId ?? "", "following")
                .then(({ data }) => {
                    setFollowingComments(data);
                    setLoadingFollowingComments(false);
                }).catch((err) => {
                    console.log("There was an error loading the comments!" + err);
                    toast.error("There was an error loading the comments!" + err);

                    setLoadingFollowingComments(false);
                });
        }
    }, [tab]);

    if (!post && !loadingPost && !loadingComments) return <NotFound />;
    return (
        <>
            <HomeWrapper>
                <HomeContainer>
                    <Userbar />

                    <PostsContainer>
                        <BackBar onClick={() => window.history.back()}><HiOutlineArrowLeft size={20} /> Back</BackBar>

                        {post && !loadingPost && <PostDisplay post={post} />}
                        {loadingPost && <PostLoadingSkeleton />}

                        <CommentCreator postId={postId ?? ""} setTopComments={setTopComments} replyId={replyId} setReplyId={setReplyId} setComments={setComments} comments={(tab === "latest" && comments) || (tab === "top" && topComments) || (tab === "following" && followingComments) || []} />

                        <Box>
                            <Button onClick={() => updateTab("latest")} active={"latest" === tab}>Latest</Button>
                            <Button onClick={() => updateTab("top")} active={"top" === tab}>Popular</Button>
                            {user && <Button onClick={() => updateTab("following")} active={"following" === tab}>Following</Button>}
                        </Box>

                        <CommentsBox>
                            {post && tab === "latest" && comments.sort((a, b) => b.comment.date - a.comment.date).map((comment, i) => <CommentDisplay followingComments={followingComments} setFollowingComments={setFollowingComments} setTopComments={setTopComments} setReplyId={setReplyId} post={post} comments={comments} setComments={setComments} topComments={topComments} comment={comment} />)}
                            {post && tab === "top" && topComments.sort((a, b) => b.comment.likes.length - a.comment.likes.length).map((comment, i) => <CommentDisplay followingComments={followingComments} setFollowingComments={setFollowingComments} setTopComments={setTopComments} setReplyId={setReplyId} post={post} comments={comments} setComments={setComments} topComments={topComments} comment={comment} />)}
                            {post && tab === "following" && followingComments.sort((a, b) => b.comment.date - a.comment.date).map((comment, i) => <CommentDisplay followingComments={followingComments} setFollowingComments={setFollowingComments} setTopComments={setTopComments} setReplyId={setReplyId} post={post} comments={comments} setComments={setComments} topComments={topComments} comment={comment} />)}

                            {loadingMore && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <div style={{ margin: '10px auto', width: 'fit-content' }}><MoonLoader size={25} /></div>

                                <Loader loading={loadingMore} children={
                                    <p style={{ cursor: 'pointer', fontSize: '15px', margin: '15px 0px', color: 'var(--accent)' }} onClick={() => {
                                        setLoadingMore(false);
                                        checkScroll();
                                    }}>Taking a bit long to load more comments? Click here to try again!</p>} />

                            </div>}

                            {tab === "following" && followingComments.length === 0 && !loadingFollowingComments && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <p style={{ color: 'var(--description-color)' }}>It's a bit quiet in here. Follow more people to see more content here.</p>
                            </div>}

                            {!loadingMore && !loadingFollowingComments && !loadingTopComments && !loadedAll && ((tab === "latest" && comments.length === 0) || (tab === "top" && topComments.length === 0)) && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <p style={{ color: 'var(--description-color)' }}>No comments yet. Be the first to comment!</p>
                            </div>}
                    

                            {loadedAll && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <p style={{ color: 'var(--description-color)' }}>You've reached the end. You've seen it all.</p>
                            </div>}
                        </CommentsBox>

                    </PostsContainer>

                    <RecommendedBar />
                </HomeContainer>
            </HomeWrapper>
        </>
    );
}