import styled from 'styled-components';

//* Styles for the Post Renderer
export const CommentBox = styled.div`
	margin-bottom: 20px;
	display: flex;
	align-items: flex-start;

	@media (max-width: 250px) {
		display: block;
	}
`;

export const Pfp = styled.img`
	width: 40px;
	object-fit: cover;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	cursor: pointer;
	margin-right: 15px;

	@media (max-width: 500px) {
		margin-right: 7.5px;
	}
`;

export const CommentContent = styled.div`
	width: calc(100% - 50px);
`;

export const NameWrapper = styled.div`
	display: flex;
	cursor: pointer;
	align-items: center;

	@media (max-width: 500px) {
		display: block;
		margin-bottom: 5px;
	}
`;

export const Displayname = styled.p`
	color: var(--text-color);
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0px;
	margin-right: 10px;

	img {
		margin-left: 5px;
	}
`;

export const Username = styled.p`
	color: var(--description-color);
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0px;
`;

export const OPTag = styled.span`
	margin-left: 7px;
	background: var(--accent);
	color: white;
	padding: 1px 5px;
	border-radius: 5px;
	font-size: 11px;
	font-weight: 400;
`;

export const CommentUserWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const CommentReply = styled.span`
	font-size: 11px;
	color: var(--description-color);
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	margin-right: 5px;
`;