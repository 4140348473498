import styled from "styled-components";

export const NavbarContainer = styled.div`
	z-index: 5;
	position: sticky;
	position: -webkit-sticky;
	top: 0px;
	width: calc(100% - 100px);
	padding: 20px 50px;
	background-color: var(--navbar-color);
	border-bottom: 1px solid var(--navbar-border);


	@media screen and (max-width: 700px) {
		padding: 20px 20px;
		width: calc(100% - 40px);
	}
`;

export const NavbarFlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const NavbarSearchFlex = styled.div`
	display: flex;
	align-items: center;

	span {
		background: var(--search-bg);
		padding: 10px;
		border-radius: 100px;
		margin-right: 15px;
		width: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 20px;
		cursor: pointer;
	}

	@media screen and (max-width: 1000px) {
		display: none;
	}
`;

export const Search = styled.div`
	background-color: var(--search-bg);
	border-radius: 26px;
	padding: 11px 15px;
	cursor: pointer;
	display: flex;
	max-width: 225px;
	align-items: center;

	@media screen and (max-width: 1000px) {
		display: none;
	}
`;

export const SearchBox = styled.p`
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 15px;
	width: 190px;
	margin: 0px;
	margin-left: 10px;
	font-family: 'Inter', sans-serif;
`;

export const FullLogo = styled.img`
	height: 25px;
	@media screen and (max-width: 700px) {
		display: none;
	}
`;

export const SmallLogo = styled.img`
	display: none;

	@media screen and (max-width: 700px) {
		display: block;
	}
`;

//* Mobile

export const MobileIcon = styled.div`
	cursor: pointer;
	display: none;
	height: 12px;
	margin-left: 19.2px;
	position: relative;
	transition: .5s ease-in-out;
	width: 15px;

	@media (max-width: 1000px) {
		display: block;
	}
`;

export const MobileLine = styled.span<{ index: number, open: boolean }>`
	top: ${props => props.index * 6}px;
	-webkit-transform-origin: left center;
	transform-origin: left center;
	background-color: var(--text-color);
	border-radius: 0;
	display: block;
	height: 1.2px;
	transform: rotate(0deg);
	left: 0;
	opacity: 1;
	border-radius: 100px;
	position: absolute;
	transition: .25s ease-in-out;
	width: 100%;

	${props => props.open && `
		&:nth-child(1) {
			left: 2.2px;
			top: -0.625px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&:nth-child(2) {
			opacity: 0;
		}

		&:nth-child(3) {
			left: 2.2px;
			top: 10.1666666667px;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	`}
`;

export const MobileMenuWrapper = styled.div`
	z-index: 4000;
	@media (min-width: 1000px) {
		display: none;
	}

	margin-top: 10px;
	margin-bottom: 7px;
	display: block;
`

export const NavbarLink = styled.a`
	cursor: pointer;
	text-decoration: none;
	color: var(--text-color);
	margin: 0px;
	display: block;
	z-index: 3;
	margin-bottom: 5px;
	font-size: 15px;
`;