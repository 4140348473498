import { useContext, useState } from "react";
import { Context } from "../utils/Context";
import { NotFound } from "./NotFound";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";
import { Userbar } from "../components/sidebar/Userbar";
import { Box } from "../styles/General";
import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { deleteUserStaff } from "../api/AUTH";
import { toast } from "react-toastify";

export const StaffPage = () => {

	const { user } = useContext(Context);

	const [username, setUsername] = useState('');

	if (!user) return <NotFound />;
	if (!['joa_sss'].includes(user.username)) return <NotFound />;
		return (
			<HomeWrapper>
					<HomeContainer>
							<Userbar />

							<PostsContainer>
									<Box>
											<h2>Admin Dashboard</h2>
											<p style={{ fontSize: '17px' }}>Welcome to the admin dashboard, {user.username}.</p><br />

											<h4>Force-Delete User Account</h4>
											<p>Enter the username of the account you want to delete.</p>
											<input onChange={(e) => setUsername(e.currentTarget.value)} type="text" placeholder="Username" /><br />
											<button onClick={() => {
												deleteUserStaff(username).then(() => {
													toast.success('User deleted successfully.');
												}).catch(() => {
													toast.error('User deletion failed.');
												});
											}}>Delete Account</button>
									</Box>
							</PostsContainer>

							<RecommendedBar />
					</HomeContainer>
			</HomeWrapper>
		);
}