import { useContext, useEffect, useMemo, useState } from "react";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";
import { Userbar } from "../components/sidebar/Userbar";
import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { useParams } from "react-router";
import { OtherUser, PostWithAuthor } from "../utils/types";
import { followUser, getUser, getUserPosts, updateUser } from "../api/USER";
import TripleDots from "../assets/icons/tripple-dots.svg";
import { ButtonBorder, ButtonFill, Displayname, DotsImg, Flex, FollowersText, Listbox, MenuButton, MenuItem, Pfp, ProfileBox, ProfilePictureUploader, Username } from "../styles/pages/Profile";
import { API_URL, SITE_URL, copy, getPfp, resize } from "../utils/constants";
import { FlagsRenderer } from "../components/root/FlagsRenderer";
import { Context } from "../utils/Context";
import { toast } from "react-toastify";
import { Menu } from "@mui/base/Menu";
import { Dropdown } from "@mui/base/Dropdown";
import { HiDotsHorizontal, HiOutlineCloudUpload, HiOutlineX } from "react-icons/hi";
import { Input, InputSelect, InputText, InputTextarea } from "../styles/pages/Login";
import { PostDisplay } from "../components/posts/Post";
import { FollowersPopup } from "../components/profiles/FollowersPopup";
import { FollowingPopup } from "../components/profiles/FollowingPopup";
import { NotFound } from "./NotFound";
import countryList from 'react-select-country-list'
import { HiOutlineClipboard, HiOutlinePencil } from "react-icons/hi2";
import { MoonLoader } from "react-spinners";
import { Loader } from "../components/root/RetryLoad";
import styled from "styled-components";

const Url = styled.a`
    :hover {
        text-decoration: underline !important;
    }
`;
export const ProfilePage = () => {

    const options = useMemo(() => countryList().getData(), []);

    const { username } = useParams();
    const { user: userSelf } = useContext(Context);

    const [user, setUser] = useState<OtherUser | null>(null);
    const [loadingUser, setLoadingUser] = useState(true);

    const [posts, setPosts] = useState<PostWithAuthor[]>([]);
    const [loadingPosts, setLoadingPosts] = useState(true);

    const [editing, setEditing] = useState(false);
    const [displayname, setDisplayname] = useState("");
    const [bio, setBio] = useState("");
    const [website, setWebsite] = useState("");
    const [newUsername, setNewUsername] = useState("");
    const [location, setLocation] = useState("");
    const [pfp, setPfp] = useState<any>('');
    const [image, setImage] = useState<any>({ preview: '', data: '' });

    const handleFileChange = (e: any) => {
        if (!userSelf) return toast.error("You must be logged in to change your profile picture.");
        if (!userSelf.verified) return toast.error("You must verify your email to change your profile picture.");

        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        });
        setPfp({
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        })
    }

    useEffect(() => {
        setEditing(false);
        setUser(null);
        setLoadingUser(true);
        getUser(username?.toLocaleLowerCase() ?? "")
            .then(({ data }) => {
                setUser(data);
                setLoadingUser(false);

                setDisplayname(data.display_name);
                setBio(data.bio);
                setWebsite(data.website);
                setNewUsername(data.username);
                setLocation(data.location);
                setPfp(data.pfp);
            }).catch((err) => {
                console.log(err);
                setLoadingUser(false);
            });

        getUserPosts(username ?? "")
            .then(({ data }) => {
                setPosts(data);
                setLoadingPosts(false);
            }).catch((err) => {
                console.log(err);
                setLoadingPosts(false);
            });
    }, [username, window.location]);

    const [following, setFollowing] = useState(false);
    const follow = (userId: string) => {
        if (following) return;
        if (!user) return toast.error("You are not logged in.");

        setFollowing(true);
        followUser(userId)
            .then(({ data }) => {
                const newFollowers = user?.followers.includes(userSelf?.userId ?? "") ? user?.followers.filter((f) => f !== userSelf?.userId) : [...user?.followers, userSelf?.userId ?? ""];
                setUser({ ...user, followers: newFollowers ?? [] });
                setFollowing(false);
            }).catch((err) => {
                console.log(err);
                toast.error(err.response.data.error);
                setFollowing(false);
            });
    }

    const [saving, setSaving] = useState(false);
    const update = async () => {
        if (saving) return;
        setSaving(true);

        let newPfp;
        if (!image.data) newPfp = pfp;

        if (image.data !== '' && (image.data.type === 'image/png' || image.data.type === 'image/jpeg' || image.data.type === 'image/jpg' || image.data.type === 'image/webp' || image.data.type === 'image/gif')) {
            let formData = new FormData();
            formData.append('file', image.data);
            const pfpResponse: any = await fetch(`${API_URL}/user/upload-pfp`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': document.cookie ? document.cookie.split(';').filter((s: string) => s.includes('token'))[0].split('=')[1] : ''
                }
            });
            if (pfpResponse.status === 500) {
                setSaving(false);
                return toast.error('Failed to upload the pfp.: Maximum allowed size is 5MB, please try again.');
            }

            const jsonRes = await pfpResponse.json();

            if (!jsonRes.file.filename) {
                setSaving(false);
                toast.error('Failed to save profile picture.');
                return;
            }
            newPfp = jsonRes.file.filename;
        }

        updateUser(displayname, bio, website, location, newUsername, newPfp)
            .then(({ data }) => {
                window.location.href = `/@${newUsername}`
            }).catch((err) => {
                console.log(err);
                toast.error(err.response.data.error);
            });
    }

    const [followersPopup, setFollowersPopup] = useState(false);
    const [followingPopup, setFollowingPopup] = useState(false);

    window.onscroll = function () { checkScroll(); };

    const [loadingMore, setLoadingMore] = useState(false);
    const [loadedAll, setLoadedAll] = useState(false);
    const checkScroll = async () => {

        const percentage = Math.floor(document.documentElement.scrollTop / (document.body.offsetHeight - window.innerHeight) * 100);

        if (percentage > 80 && posts && !loadingMore && !loadedAll) {
            setLoadingMore(true);

            const { data: newPosts } = await getUserPosts(username ?? "", posts[posts.length - 1].post.date);
            if (!newPosts) return setLoadingMore(false);
            if (!newPosts[0]) {
                setLoadingMore(false);
                setLoadedAll(true);
                return;
            }
            if (newPosts[0].postId !== posts[posts.length - 1].post.postId) setPosts(posts.concat(newPosts));

            setLoadingMore(false);
        }
    }


    if (!user && !loadingUser) return <NotFound />;
    return (
        <>
            <HomeWrapper>
                <HomeContainer>
                    <Userbar />

                    <PostsContainer>

                        {!loadingUser && !user && <p>That user doesn't exist!</p>}
                        {!loadingUser && user && <ProfileBox>
                            {!editing && <div style={{ width: '100%' }}>
                                <Flex>
                                    <Pfp src={getPfp(user.display_name, user.pfp)} />

                                    <div style={{ width: '100%', marginRight: '30px' }}>
                                        <Flex style={{ justifyContent: 'space-between' }}>
                                            <Displayname>{user.display_name} <FlagsRenderer flags={user.flags} /></Displayname>
                                            {userSelf && !user?.followers.includes(userSelf.userId) && user?.userId !== userSelf.userId && <ButtonFill onClick={() => follow(user.userId)}>Follow</ButtonFill>}
                                            {userSelf && user?.followers.includes(userSelf.userId) && user?.userId !== userSelf.userId && <ButtonBorder onClick={() => follow(user.userId)}>Unfollow</ButtonBorder>}
                                        </Flex>
                                        <Username>@{user.username}</Username>
                                    </div>
                                </Flex>

                                <p style={{ marginTop: '8px', whiteSpace: 'pre-wrap' }}>{user.bio}</p>
                                <Url style={{ color: '#5597a9', textDecoration: 'none' }} target="_blank" href={user.website} rel="noreferrer">{user.website}</Url>
                                <p style={{ margin: '0px', color: 'var(--description-color)' }}>{user.location}</p>

                                <Flex style={{ marginTop: '15px' }}>
                                    <FollowersText style={{ cursor: 'pointer' }} onClick={() => setFollowersPopup(true)}><span>{user.followers.length}</span> followers  <span>• </span></FollowersText>
                                    <FollowersText style={{ cursor: 'pointer' }} onClick={() => setFollowingPopup(true)}><span>{user.following.length}</span> following</FollowersText>
                                </Flex>
                            </div>}

                            {editing && <div style={{ width: '100%' }}>

                                <h3>Editing Profile</h3>

                                <InputText>Displayname</InputText>
                                <Input id="displayname" value={displayname} onChange={(e) => setDisplayname(e.currentTarget.value)} placeholder="Displayname" />

                                <InputText>Username (@username)</InputText>
                                <Input id="username" value={newUsername} onChange={(e) => setNewUsername(e.currentTarget.value)} placeholder="Username" />

                                <InputText>Bio</InputText>
                                <InputTextarea id="bio" value={bio} onChange={(e) => {
                                    setBio(e.currentTarget.value);
                                    resize(e.currentTarget, true);
                                }} placeholder="Bio" />

                                <InputText>Website</InputText>
                                <Input id="website" value={website} onChange={(e) => setWebsite(e.currentTarget.value)} placeholder="Website" />

                                <InputText>Location</InputText>
                                <InputSelect id="location" value={location} onChange={(e) => setLocation(e.currentTarget.value)} placeholder="Location">
                                    {options.map((c: any) => <option key={c.value} value={c.label}>{c.label}</option>)}
                                </InputSelect>

                                <br />
                                <InputText>Profile Picture</InputText>
                                <Flex style={{ alignItems: 'center' }}>
                                    <img style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '8px' }} src={image.preview || getPfp(displayname, pfp)} />
                                    <HiOutlineX size={20} style={{ cursor: 'pointer', marginLeft: '10px' }} color="#8f3535" onClick={() => {
                                        setImage({ preview: '', data: '' });
                                        setPfp(undefined);
                                    }} />
                                </Flex><br />

                                <ProfilePictureUploader title="Upload image" htmlFor="pfp-upload"><HiOutlineCloudUpload size={18} /> Upload Image</ProfilePictureUploader>
                                <input id='pfp-upload' accept="image/*" style={{ display: 'none', height: '0px' }} type='file' name='file' onChange={handleFileChange} /><br />
                                <br />
                                <ButtonFill onClick={update}>Save Changes</ButtonFill>
                            </div>}

                            <Dropdown>
                                <MenuButton><HiDotsHorizontal color="var(--text-color)" /></MenuButton>
                                <Menu slots={{ listbox: Listbox }}>
                                    <MenuItem onClick={() => copy(`${SITE_URL}/@${user.username}`)}><HiOutlineClipboard /> Copy Link</MenuItem>
                                    {userSelf && user.userId === userSelf.userId && <MenuItem onClick={() => setEditing(!editing)}><HiOutlinePencil /> Edit Profile</MenuItem>}
                                </Menu>
                            </Dropdown>
                        </ProfileBox>}

                        <div>
                            {posts && !loadingPosts && user && posts.sort((a, b) => b.post.date - a.post.date).map((post) => <PostDisplay post={post} disableActions />)}

                            {loadingMore && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <div style={{ margin: '10px auto', width: 'fit-content' }}><MoonLoader size={25} /></div>
    
                                <Loader loading={loadingMore} children={
                                    <p style={{ cursor: 'pointer', fontSize: '15px', margin: '15px 0px', color: 'var(--accent)' }} onClick={() => {
                                        setLoadingMore(false);
                                        checkScroll();
                                    }}>Taking a bit long to load more posts? Click here to try again!</p>} />
    
                            </div>}
    
                            {loadedAll && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <p style={{ color: 'var(--description-color)' }}>You've reached the end. You've seen it all.</p>
                            </div>}
                        </div>
                    </PostsContainer>

                    <RecommendedBar />
                </HomeContainer>
            </HomeWrapper>

            {followersPopup && user && <FollowersPopup otherUser={user} setPopup={setFollowersPopup} username={username ?? ''} />}
            {followingPopup && user && <FollowingPopup otherUser={user} setPopup={setFollowingPopup} username={username ?? ''} />}
        </>
    );
}