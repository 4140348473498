import styled from 'styled-components';

export const Background = styled.div`
	background: rgba(3, 3, 3, 0.20);
	width: 100vw;
	height: 100vh;
	top: 0px;
	left: 0px;
	position: fixed;
	z-index: 100;
`;

export const PopupElement = styled.div`
	background: var(--navbar-color);
	border-radius: 5px;
	position: fixed;
	top: 20%;
	left: 50%;
	max-height: calc(65%);
	overflow-y: scroll;
	transform: translateX(-50%);
	max-width: 600px;
	width: 100%;
`;

export const PopupTitle = styled.div`
	padding: 12px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--navbar-border);
`;

export const PopupTitleText = styled.p`
	color: var(--color);
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0px;
	margin-right: 30px;
`;

export const PopupClose = styled.div`
	cursor: pointer;
	border-radius: 50px;
	background: rgba(0, 0, 0, 0.35);
	padding: 7px;
	height: 20px;
`;