import styled from "styled-components";

export const BoxContainer = styled.div`
	max-width: 500px;
	text-align: left;
	margin: 0px auto;
`;

export const SettingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0px;
	cursor: pointer;
`;