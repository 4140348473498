import styled from 'styled-components';


export const HomeWrapper = styled.div`
    margin: 0px 50px;

    @media screen and (max-width: 700px) {
        margin: 0px 20px;
    }
`;

export const HomeContainer = styled.div`
    display: grid;
    grid-template-columns: 325px 1fr 325px;
    gap: 30px;

    @media screen and (max-width: 1350px) {
        grid-template-columns: 325px 1fr;
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 0px 1fr 0px;
        gap: 0px;
    }
`;

export const PostsContainer = styled.div`
    border-radius: 8px;
    margin-bottom: 25px;
    margin-top: 35px;
    max-width: calc(100vw - 40px);
`;