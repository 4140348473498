//* Post Creator Box Component
import { useContext, useEffect, useRef, useState } from "react";

import { Context } from "../../utils/Context";
import { useNavigate } from "react-router";

import { getLikes } from "../../api/POST";
import { OtherUser, Post } from "../../utils/types";
import { getPfp } from "../../utils/constants";
import { toast } from "react-toastify";
import { followUser } from "../../api/USER";
import { FlagsRenderer } from "../root/FlagsRenderer";
import { Popup } from "../root/Popup";
import Skeleton from "react-loading-skeleton";
import { Displayname, Flex, Pfp, User, Username, ButtonBorder, ButtonFill, LoadMore, PfpWrapper, PopupDiv } from "../../styles/components/posts/LikesPopup";

type Props = {
	postId: string;
	setPopup: any;
	post: Post;
};

export const LikesPopup = ({ postId, setPopup, post }: Props) => {

	const { user, setUser } = useContext(Context);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [likes, setLikes] = useState<OtherUser[]>([]);

	const fetched = useRef(false);
	useEffect(() => {
		if (fetched.current) return;
		fetched.current = true;

		document.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') setPopup(false);
		});

		getLikes(postId, likes.length)
			.then(({ data }) => {
				setLikes(data);
				setLoading(false);
			}).catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}, [postId, setPopup]);


	const [following, setFollowing] = useState(false);
	const follow = (userId: string) => {
		if (following) return;
		if (!user) return toast.error("You are not logged in.");

		setFollowing(true);
		followUser(userId)
			.then(({ data }) => {
				setUser(data);
				setFollowing(false);
			}).catch((err) => {
				console.log(err);
				toast.error(err.response.data.error);
				setFollowing(false);
			});
	}

	return (
		<Popup content={
			<div>
				{likes.map((l) => <User>
					<Flex onClick={() => navigate(`/@${l.username}`)}>
						<Pfp src={getPfp(l.display_name, l.pfp)} />
						<div>
							<Displayname>{l.display_name} <FlagsRenderer flags={l.flags} /></Displayname>
							<Username>@{l.username}</Username>
						</div>
					</Flex>

					{user && !user?.following.includes(l.userId) && user?.userId !== l.userId && <ButtonFill id="popup" onClick={() => follow(l.userId)}>Follow</ButtonFill>}
					{user && user?.following.includes(l.userId) && user?.userId !== l.userId && <ButtonBorder id="popup" onClick={() => follow(l.userId)}>Unfollow</ButtonBorder>}
				</User>)}

				{!loading && post.likes.length !== likes.length && <PopupDiv>
					<LoadMore onClick={() => {
						getLikes(postId, likes.length)
							.then(({ data }) => {
								setLikes(likes.concat(data));
								setLoading(false);
							}).catch((err) => {
								console.log(err);
								setLoading(false);
							});
					}}>Load more likes</LoadMore>
				</PopupDiv>}

				{likes.length === 0 && !loading && <PopupDiv><b>This post has no likes.</b></PopupDiv>}

				{loading && [...Array(5)].map(() => <User loading>
					<Flex>
						<PfpWrapper>
							<Skeleton width={40} height={40} borderRadius={50} />
						</PfpWrapper>

						<div>
							<Skeleton width={150} />
							<Skeleton width={80} />
						</div>
					</Flex>
				</User>)}
			</div>} title="Likes" close={() => setPopup(false)} />
	)
}