import axios from "axios";
import { API_URL, RequestHeader } from "../utils/constants";


export const registerUser = async (displayname: any, username: any, password: any, email: any) =>
    await axios.post(`${API_URL}/auth/register`, { displayname, username, password, email }, RequestHeader);

export const registerCheck = async (username: any, email: any) =>
    await axios.post(`${API_URL}/auth/register-check`, { username, email }, RequestHeader);

export const checkUsername = async (username: any) =>
    await axios.post(`${API_URL}/auth/check-username`, { username }, RequestHeader);

export const checkEmail = async (email: any) =>
    await axios.post(`${API_URL}/auth/check-email`, { email }, RequestHeader);

export const loginUser = async (username: any, password: any) =>
    await axios.post(`${API_URL}/auth/login`, { username, password }, RequestHeader);


export const authUser = async () =>
    await axios.post(`${API_URL}/auth/user`, {}, RequestHeader);


export const sendResetEmail = async (email: any) =>
    await axios.post(`${API_URL}/auth/reset-password`, { email }, RequestHeader);

export const checkVerificationCode = async (code: any, encryptedCode: any, email: any) =>
    await axios.post(`${API_URL}/auth/reset-password-code`, { code, encryptedCode, email }, RequestHeader);

export const savePassword = async (password: any, email: any, code: any, encryptedCode: any, token: any) =>
    await axios.post(`${API_URL}/auth/reset-password-save`, { password, email, code, encryptedCode, token }, RequestHeader);

export const deleteUserStaff = async (username: string) =>
    await axios.post(`${API_URL}/auth/delete-user-staff`, { username }, RequestHeader);