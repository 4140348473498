import styled from 'styled-components';

export const User = styled.div<{ loading?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 20px;

	:first-child {
		margin-top: 10px;
	}

	:last-child {
		margin-bottom: 10px;
	}

	${props => !props.loading && `
		:hover {
			background: rgba(0, 0, 0, 0.05);
		}
	`}
`;

export const Flex = styled.div`
	display: flex;
	cursor: pointer;
	align-items: center;
`;

export const Pfp = styled.img`
	border-radius: 50%;
	object-fit: cover;
	width: 40px;
	height: 40px;
	margin-right: 15px;
`;

export const Displayname = styled.p`
	color: var(--text-color);
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0px;
	display: flex;
	align-items: center;

	img {
		margin-left: 5px;
	}
`;

export const Username = styled.p`
	color: var(--description-color);
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0px;
`;

export const ButtonFill = styled.a`
  background-color: white;
  color: var(--accent);
  padding: 6px 20px;
  margin: 0px;
  border: 1px solid var(--accent);
  border-radius: 20px;

  :hover {
      color: var(--accent-hover);
			text-decoration: none;
  }
`;

export const ButtonBorder = styled.a`
  color: white;
  padding: 6px 20px;
  margin: 0px;
  background-color: var(--accent);
  border-radius: 20px;

  :hover {
      color: #fff;
      background-color: var(--accent-hover);
			text-decoration: none;
  }
`;

export const PopupDiv = styled.div`
	margin: 12px 20px;
`;

export const PfpWrapper = styled.div`
	margin-right: 15px;
`;

export const LoadMore = styled.b`
	cursor: pointer;
	padding: 8px 12px;
	display: block;
	width: fit-content;
	border-radius: 5px;
	font-size: 15px;
	background-color: var(--accent);
	margin-top: 15px;

	:hover {
		background-color: var(--accent-hover);
	}
`;