import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { Userbar } from "../components/sidebar/Userbar";
import { Box } from "../styles/General";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";

export const NotFound = () => (
    <>
        <HomeWrapper>
            <HomeContainer>
                <Userbar />

                <PostsContainer>
                    <Box>
                        <h2>Sorry, page not found.</h2>
                        <p style={{ fontSize: '17px' }}>It seems that the page you are looking for does not exist.</p>
                        <a onClick={() => window.history.back()} href="#">{'<-'} Previous page</a>
                    </Box>
                </PostsContainer>

                <RecommendedBar />
            </HomeContainer>
        </HomeWrapper>
    </>
)