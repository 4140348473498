import { useEffect, useState, useContext } from "react";
import { getBookmarks } from "../api/POST";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";
import { Userbar } from "../components/sidebar/Userbar";
import { PostDisplay } from "../components/posts/Post";
import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { PostWithAuthor } from "../utils/types";
import { BackBar } from "../styles/pages/Post";
import { HiOutlineArrowLeft } from "react-icons/hi2";
import { toast } from "react-toastify";
import { PostLoadingSkeleton } from "../components/loading/Post";
import { NotFound } from "./NotFound";
import { MoonLoader } from "react-spinners";
import { Loader } from "../components/root/RetryLoad";
import { Context } from "../utils/Context";


export const BookmarksPage = () => {

    const { user, loading, bookmarks:bookmarkList } = useContext(Context);

    const [bookmarks, setBookmarks] = useState<PostWithAuthor[]>([]);
    const [loadingBookmarks, setLoadingBookmarks] = useState(true);
    const [loadedAll, setLoadedAll] = useState(false);

    useEffect(() => {
        getBookmarks(0)
            .then(({ data }) => {
                setBookmarks(data);
                setLoadingBookmarks(false);
            }).catch((err) => {
                console.log("There was an error loading the bookmarked posts!" + err);
                toast.error("There was an error loading the bookmarked posts! " + err);

                setLoadingBookmarks(false);
            });
    }, []);

    //* When a post is un-bookmarked, remove it from the list here.
    useEffect(() => {
        if (!bookmarkList) return;
        setBookmarks(bookmarks.filter((b) => bookmarkList.includes(b.post.postId)));
    }, [bookmarkList]);


    window.onscroll = function () { checkScroll(); };

    const [loadingMore, setLoadingMore] = useState(false);
    const checkScroll = async () => {

        const percentage = Math.floor(document.documentElement.scrollTop / (document.body.offsetHeight - window.innerHeight) * 100);

        if (percentage > 80 && bookmarks && !loadingMore && !loadedAll) {
            setLoadingMore(true);
            const { data: newBookmarks } = await getBookmarks(bookmarks.length);
            if (!newBookmarks) return setLoadingMore(false);
            if (!newBookmarks[0]) {
                setLoadingMore(false);
                setLoadedAll(true);
                return;
            }
            if (newBookmarks[0].post.postId !== newBookmarks[newBookmarks.length - 1].post.postId) setBookmarks(bookmarks.concat(newBookmarks));

            setLoadingMore(false);
        }
    }

    if (!user && !loading) return <NotFound />;
    return (
        <>
            <HomeWrapper>
                <HomeContainer>
                    <Userbar />

                    <PostsContainer>
                        <BackBar onClick={() => window.history.back()}><HiOutlineArrowLeft size={20} /> Back</BackBar>

                        {loadingBookmarks && [...Array(30)].map((_, i) => <PostLoadingSkeleton />)}

                        <div>
                            {bookmarks.sort((a, b) => b.post.date - a.post.date).map((p) => <PostDisplay post={p} />)}
                        </div>
                        {!loadingBookmarks && bookmarks.length === 0 && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <p style={{ color: 'var(--description-color)' }}>No saved posts yet.</p>
                        </div>}

                        {loadingMore && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <div style={{ margin: '10px auto', width: 'fit-content' }}><MoonLoader size={25} /></div>

                            <Loader loading={loadingMore} children={
                                <p style={{ cursor: 'pointer', fontSize: '15px', margin: '15px 0px', color: '#4e7baa' }} onClick={() => {
                                    setLoadingMore(false);
                                    checkScroll();
                                }}>Taking a bit long to load more saved posts? Click here to try again!</p>} />

                        </div>}

                        {loadedAll && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <p style={{ color: 'var(--description-color)' }}>That's all. Save more posts to see more.</p>
                        </div>}
                    </PostsContainer>

                    <RecommendedBar />
                </HomeContainer>
            </HomeWrapper>
        </>
    );
}