//* Post Creator Box Component
import { useContext, useEffect, useRef, useState } from "react";

import { Context } from "../../utils/Context";
import { useNavigate } from "react-router";

import { OtherUser } from "../../utils/types";
import { getPfp } from "../../utils/constants";
import { toast } from "react-toastify";
import { followUser, getFollowers } from "../../api/USER";
import { FlagsRenderer } from "../root/FlagsRenderer";
import { Popup } from "../root/Popup";
import Skeleton from "react-loading-skeleton";
import { Displayname, Flex, Pfp, User, Username, ButtonBorder, ButtonFill, LoadMore, PfpWrapper, PopupDiv } from "../../styles/components/posts/LikesPopup";

type Props = {
	username: string;
	setPopup: any;
	otherUser: OtherUser;
};
export const FollowersPopup = ({ otherUser, setPopup, username }: Props) => {

	const { user, setUser } = useContext(Context);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [followers, setFollowers] = useState<OtherUser[]>([]);

	const fetched = useRef(false);
	useEffect(() => {
		if (fetched.current) return;
		fetched.current = true;

		document.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') setPopup(false);
		});

		getFollowers(username, followers.length)
			.then(({ data }) => {
				setFollowers(data);
				setLoading(false);
			}).catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}, [username, setPopup]);


	const [following, setFollowing] = useState(false);
	const follow = (userId: string) => {
		if (following) return;
		if (!user) return toast.error("You are not logged in.");

		setFollowing(true);
		followUser(userId)
			.then(({ data }) => {
				setUser(data);
				setFollowing(false);
			}).catch((err) => {
				console.log(err);
				toast.error(err.response.data.error);
				setFollowing(false);
			});
	}

	return (
		<Popup content={
			<div>
				{followers.map((l) => <User>
					<Flex onClick={() => navigate(`/@${l.username}`)}>
						<Pfp src={getPfp(l.display_name, l.pfp)} />
						<div>
							<Displayname>{l.display_name} <FlagsRenderer flags={l.flags} /></Displayname>
							<Username>@{l.username}</Username>
						</div>
					</Flex>

					{user && !user?.following.includes(l.userId) && user?.userId !== l.userId && <ButtonFill id="popup" onClick={() => follow(l.userId)}>Follow</ButtonFill>}
					{user && user?.following.includes(l.userId) && user?.userId !== l.userId && <ButtonBorder id="popup" onClick={() => follow(l.userId)}>Unfollow</ButtonBorder>}
				</User>)}

				{!loading && otherUser.followers.length !== followers.length && <PopupDiv>
					<LoadMore onClick={() => {
						getFollowers(username, followers.length)
							.then(({ data }) => {
								setFollowers(followers.concat(data));
								setLoading(false);
							}).catch((err) => {
								console.log(err);
								setLoading(false);
							});
					}}>Load more followers</LoadMore>
				</PopupDiv>}

				{followers.length === 0 && !loading && <PopupDiv><b>This user has no followers.</b></PopupDiv>}

				{loading && [...Array(5)].map(() => <User loading>
					<Flex>
						<PfpWrapper>
							<Skeleton width={40} height={40} borderRadius={50} />
						</PfpWrapper>

						<div>
							<Skeleton width={150} />
							<Skeleton width={80} />
						</div>
					</Flex>
				</User>)}
			</div>} title={`Followers for @${username}`} close={() => setPopup(false)} />
	)
}