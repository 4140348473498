import { useContext, useEffect, useState } from "react";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";
import { Userbar } from "../components/sidebar/Userbar";
import { PostCreator } from "../components/posts/PostCreator";
import { PostDisplay } from "../components/posts/Post";
import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { Context } from "../utils/Context";
import { PostLoadingSkeleton } from "../components/loading/Post";
import { getMore, getPosts } from "../api/POST";
import { MoonLoader } from "react-spinners";
import { Loader } from "../components/root/RetryLoad";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const Box = styled.div`
    padding: 10px 20px;
    background: var(--navbar-color);
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    margin-bottom: 10px;
    display: flex;
    position: sticky;
    top: 80px;
    z-index: 10;
`;

const Button = styled.a<{ active: boolean }>`
    border: 0px solid white;
    transition: 0.1s;
    width: fit-content !important;
    color: rgb(134, 142, 150);
    padding: 6px 14px;
    margin: 0px;
    border-radius: 32px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    display: block;
    text-decoration: none !important;

    
    ${({ active }) => !active && `
        :hover {    
            color: rgb(134, 142, 150);
            background-color: var(--hover-color);
        }

        &:active {
            background-color: rgb(166, 173, 180);
            color: white;
        }
    `}


    ${({ active }) => active && `
        background-color: rgb(134, 142, 150);
        color: white;

        
        :hover {
            color: #fff;
            cursor: default;
        }

        &:active {
            background-color: rgb(116, 123, 129);
        }
    `}

    &:active {
        transform: translateY(1px);
    }
`;

export const Home = () => {
    const { posts, setPosts, loadingPosts, user, topPosts, setTopPosts, loadingTopPosts, setLoadingTopPosts, followingPosts, setFollowingPosts, loadingFollowingPosts, setLoadingFollowingPosts } = useContext(Context);

    const [repostId, setRepostId] = useState("");



    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get("tab") || "latest");
    const updateTab = (tab: string) => {
        if (loadingMore || loadingPosts || loadingFollowingPosts || loadingTopPosts) return;
        setSearchParams({ tab });
        setTab(tab);
    }


    window.onscroll = function () { checkScroll(); };

    const [loadingMore, setLoadingMore] = useState(false);
    const [loadedAll, setLoadedAll] = useState(false);
    const checkScroll = async () => {

        const percentage = Math.floor(document.documentElement.scrollTop / (document.body.offsetHeight - window.innerHeight) * 100);

        if (percentage > 80 && posts && !loadingMore && !loadedAll) {
            setLoadingMore(true);

            if (tab === "latest") {
                const { data: newPosts } = await getMore(posts.length, tab);
                if (!newPosts) return setLoadingMore(false);
                if (!newPosts[0]) {
                    setLoadingMore(false);
                    setLoadedAll(true);
                    return;
                }
                if (newPosts[0].post.postId !== posts[posts.length - 1].post.postId) setPosts(posts.concat(newPosts));

                setLoadingMore(false);
            } else if (tab === "following") {
                const { data: newPosts } = await getMore(followingPosts.length, tab);
                if (!newPosts) return setLoadingMore(false);
                if (!newPosts[0]) {
                    setLoadingMore(false);
                    setLoadedAll(true);
                    return;
                }
                if (newPosts[0].post.postId !== followingPosts[followingPosts.length - 1].post.postId) setFollowingPosts(followingPosts.concat(newPosts));

                setLoadingMore(false);
            } else if (tab === "top") {
                const { data: newPosts } = await getMore(topPosts.length, tab);
                if (!newPosts) return setLoadingMore(false);
                if (!newPosts[0]) {
                    setLoadingMore(false);
                    setLoadedAll(true);
                    return;
                }
                if (newPosts[0].post.postId !== topPosts[topPosts.length - 1].post.postId) setTopPosts(topPosts.concat(newPosts));

                setLoadingMore(false);
            }
        }
    }

    useEffect(() => {
        setLoadedAll(false);
        setLoadingMore(false);

        if (tab === "top" && topPosts.length === 0 && !loadingTopPosts) {
            setLoadingTopPosts(true);
            getPosts("top")
                .then(({ data }) => {
                    setTopPosts(data);
                    setLoadingTopPosts(false);
                }).catch((err) => {
                    console.log("There was an error loading the posts!" + err);
                    toast.error("There was an error loading the posts!" + err);

                    setLoadingTopPosts(false);
                });
        } else if (tab === "following" && followingPosts.length === 0 && !loadingFollowingPosts) {
            setLoadingFollowingPosts(true);
            getPosts("following")
                .then(({ data }) => {
                    setFollowingPosts(data);
                    setLoadingFollowingPosts(false);
                }).catch((err) => {
                    console.log("There was an error loading the posts!" + err);
                    toast.error("There was an error loading the posts!" + err);

                    setLoadingFollowingPosts(false);
                });
        }
    }, [tab]);

    return (
        <>
            <HomeWrapper>
                <HomeContainer>
                    <Userbar />

                    <PostsContainer>
                        <PostCreator tab={tab} repostId={repostId} setRepostId={setRepostId} />

                        <Box>
                            <Button onClick={() => updateTab("latest")} active={"latest" === tab}>Latest</Button>
                            <Button onClick={() => updateTab("top")} active={"top" === tab}>Popular</Button>
                            {user && <Button onClick={() => updateTab("following")} active={"following" === tab}>Following</Button>}
                        </Box>

                        {tab === "latest" && loadingPosts && [...Array(30)].map((_, i) => <PostLoadingSkeleton />)}
                        {tab === "top" && loadingTopPosts && [...Array(30)].map((_, i) => <PostLoadingSkeleton />)}
                        {tab === "following" && loadingFollowingPosts && [...Array(30)].map((_, i) => <PostLoadingSkeleton />)}

                        <div>
                            {tab === "latest" && posts.sort((a, b) => b.post.date - a.post.date).map((p) => <PostDisplay setRepostId={setRepostId} post={p} />)}
                            {tab === "top" && topPosts.sort((a, b) => b.post.likes.length - a.post.likes.length).map((p) => <PostDisplay setRepostId={setRepostId} post={p} />)}
                            {tab === "following" && followingPosts.sort((a, b) => b.post.date - a.post.date).map((p) => <PostDisplay setRepostId={setRepostId} post={p} />)}
                        </div>

                        {loadingMore && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <div style={{ margin: '10px auto', width: 'fit-content' }}><MoonLoader size={25} /></div>

                            <Loader loading={loadingMore} children={
                                <p style={{ cursor: 'pointer', fontSize: '15px', margin: '15px 0px', color: '#4e7baa' }} onClick={() => {
                                    setLoadingMore(false);
                                    checkScroll();
                                }}>Taking a bit long to load more posts? Click here to try again!</p>} />

                        </div>}

                        {loadedAll && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <p style={{ color: 'var(--description-color)' }}>You've reached the end. You've seen it all.</p>
                        </div>}

                        {tab === "following" && followingPosts.length === 0 && !loadingFollowingPosts && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <p style={{ color: 'var(--description-color)' }}>It's a bit quiet in here. Follow more people to see more content here.</p>
                        </div>}
                    </PostsContainer>

                    <RecommendedBar />
                </HomeContainer>
            </HomeWrapper>
        </>
    );
}