import { useEffect, useState, useContext } from "react";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";
import { Userbar } from "../components/sidebar/Userbar";
import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { useNavigate } from "react-router";
import { Notification } from "../utils/types";
import { BackBar, CommentsBox } from "../styles/pages/Post";
import { HiOutlineArrowLeft } from "react-icons/hi2";
import { NotFound } from "./NotFound";
import { MoonLoader } from "react-spinners";
import { Loader } from "../components/root/RetryLoad";
import { Context } from "../utils/Context";
import styled from "styled-components";
import { getUserNotifications } from "../api/USER";
import { getPfp } from "../utils/constants";
import moment from "moment";

const Box = styled.div`
    padding: 10px 20px;
    background: var(--navbar-color);
    display: flex;
    border-radius: 8px 8px 0px 0px;
    margin-top: 10px;
`;

const Button = styled.a<{ active: boolean }>`
    border: 0px solid white;
    transition: 0.1s;
    width: fit-content !important;
    color: rgb(134, 142, 150);
    padding: 6px 14px;
    margin: 0px;
    border-radius: 32px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    display: block;

    
    ${({ active }) => !active && `
        :hover {    
            color: rgb(134, 142, 150);
            background-color: var(--hover-color);
        }

        &:active {
            background-color: rgb(166, 173, 180);
            color: white;
        }
    `}


    ${({ active }) => active && `
        background-color: rgb(134, 142, 150);
        color: white;

        
        :hover {
            color: #fff;
            cursor: default;
        }

        &:active {
            background-color: rgb(116, 123, 129);
        }
    `}

    &:active {
        transform: translateY(1px);
    }
`;

const NotificationDisplay = styled.div<{ unread: boolean }>`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    display: flex;
    align-items: center;

    ${({ unread }) => unread && `
        border-left: 3px solid var(--accent);
        border-radius: 1px;
        margin-left: -25px;
        padding-left: 22px;
    `}
`;

const Pfp = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
`;

const NotifiationContent = styled.p`
    margin: 0px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    a {
        color: var(--text-colo) !important;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
        margin: 0px;
    }

    span {
        margin: 0px;
        font-size: 14px !important;
        color: var(--text-color) !important;
        font-weight: 400;
        cursor: pointer;

        :hover {
            text-decoration: underline;
        }
    }
`;

const NotificationBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
        margin: 0px;
        font-size: 13px;
        font-weight: 400;
        margin-right: 5px;
        color: var(--description-color);
    }
`;


export const NotificationsPage = () => {

    const navigate = useNavigate();

    const { user, loading, notifications, setNotifications, loadingNotifications, loadedAllNotifications, setLoadedAllNotifications } = useContext(Context);

    window.onscroll = function () { checkScroll(); };

    const [loadingMore, setLoadingMore] = useState(false);
    const checkScroll = async () => {

        const percentage = Math.floor(document.documentElement.scrollTop / (document.body.offsetHeight - window.innerHeight) * 100);

        if (percentage > 80 && notifications && !loadingMore && !loadedAllNotifications) {
            setLoadingMore(true);
            const { data: newNotifications } = await getUserNotifications(notifications.length);
            if (!newNotifications) return setLoadingMore(false);
            if (!newNotifications[0]) {
                setLoadingMore(false);
                setLoadedAllNotifications(true);
                return;
            }
            if (newNotifications[0].notification.date !== notifications[notifications.length - 1].notification.date) setNotifications(notifications.concat(newNotifications));

            setLoadingMore(false);
        }
    }

    const [sort, setSort] = useState("all");
    const [sortedNotifications, setSortedNotifications] = useState<Notification[]>(notifications);
    useEffect(() => {
        if (sort === "all") return setSortedNotifications(notifications);
        setSortedNotifications(notifications.filter((notification) => notification.notification.category === sort));
    }, [sort, notifications]);

    useEffect(() => {
        //* Mark all as read
        const newNotifications = notifications.map((notification) => {
            notification.notification.unread = false;
            return notification;
        });
        setNotifications(newNotifications);
    }, []);

    if (!user && !loading) return <NotFound />;
    return (
        <>
            <HomeWrapper>
                <HomeContainer>
                    <Userbar />

                    <PostsContainer>
                        <BackBar onClick={() => window.history.back()}><HiOutlineArrowLeft size={20} /> Back</BackBar>

                        <Box>
                            <Button onClick={() => setSort("all")} active={"all" === sort}>All activity</Button>
                            <Button onClick={() => setSort("like")} active={"like" === sort}>Likes</Button>
                            <Button onClick={() => setSort("follow")} active={"follow" === sort}>Followers</Button>
                            <Button onClick={() => setSort("comment")} active={"comment" === sort}>Comments</Button>
                            <Button onClick={() => setSort("mention")} active={"mention" === sort}>Mentions</Button>
                        </Box>

                        <CommentsBox style={{ paddingTop: '5px' }}>
                            {loadingNotifications && <MoonLoader size={25} color="#0ca5e297" />}

                            {sortedNotifications.map((notification) => <NotificationDisplay unread={notification.notification.unread}>
                                <Pfp onClick={() => navigate(`/@${notification.user.username}`)} src={getPfp(notification.user.display_name, notification.user.pfp)} alt="Profile Picture" />

                                <NotificationBox>
                                    <NotifiationContent>
                                        <span onClick={() => navigate(`/@${notification.user.username}`)}>{notification.user.display_name}</span>
                                        <a onClick={() => navigate(`${notification.notification.link}`)}> {notification.notification.content}</a>
                                    </NotifiationContent>

                                    <span>{moment(notification.notification.date).fromNow()}</span>
                                </NotificationBox>
                            </NotificationDisplay>)}

                            {sortedNotifications.length === 0 && !loadingNotifications && <p style={{ textAlign: "center", marginTop: "20px", color: '#000000ae' }}>No notifications to show.</p>}

                            {loadingMore && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <div style={{ margin: '10px auto', width: 'fit-content' }}><MoonLoader size={25} /></div>

                                <Loader loading={loadingMore} children={
                                    <p style={{ cursor: 'pointer', fontSize: '15px', margin: '15px 0px', color: '#4e7baa' }} onClick={() => {
                                        setLoadingMore(false);
                                        checkScroll();
                                    }}>Taking a bit long to load more comments? Click here to try again!</p>} />

                            </div>}

                            {loadedAllNotifications && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                                <p style={{ color: 'var(--description-color)' }}>You've reached the end. You've seen it all.</p>
                            </div>}
                        </CommentsBox>
                    </PostsContainer>

                    <RecommendedBar />
                </HomeContainer>
            </HomeWrapper>
        </>
    );
}