import styled from "styled-components";

export const SearchTabs = styled.div`
    background: var(--navbar-color);
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    padding: 0px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px 0px var(--box-shadow);

    p {
        margin: 0px;
        margin-right: 10px;
        cursor: pointer;
        padding: 10px 5px;
        border-bottom: 3px solid var(--navbar-color);
    }
`;

export const SearchBox = styled.p<{ active: boolean }>`
    ${({ active }) => active && `
        border-bottom: 3px solid var(--accent) !important;
    `}
`; 

export const SearchBar = styled.div`
    background: var(--navbar-color);
    padding: 2.5px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px 0px var(--box-shadow);

    input {
        width: 100%;
        padding: 10px 0px;
        font-size: 15px;
        font-weight: 400;
        border: none;
        font-family: 'Inter', sans-serif;
        outline: none;
        background: none;
        color: var(--text-color);
    }

    svg {
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
    }
`;

export const User = styled.div`
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0px;
    }
`;

export const Pfp = styled.img`
    border-radius: 50%;
    object-fit: cover;
    width: 40px;
    height: 40px;
    margin-right: 15px;
`;

export const Username = styled.p`
    margin: 0px;
    color: var(--description-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const Displayname = styled.p`
    color: var(--text-color);
    margin: 0px;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;

    img {
        margin-left: 5px;
    }
`;

export const SearchedUsersBox = styled.div`
    background: var(--navbar-color);
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
    padding: 20px 25px;
    border-radius: 8px;
`;