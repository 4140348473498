import { useNavigate } from "react-router";
import { PostWithAuthor } from "../../../utils/types";
import { getPfp } from "../../../utils/constants";
import { formatText } from "../../../utils/Markdown";
import { PostAttachments } from "./Attachments";
import { RepostBox, RepostClose, RepostCloseFlex, RepostComments, RepostContent, RepostUser } from "../../../styles/components/posts/Post";
import { HiOutlineX } from "react-icons/hi";

type Props = {
	repost?: PostWithAuthor;
	disableClick?: boolean;
}
export const PostRepost = ({ repost, disableClick }: Props) => {

	const navigate = useNavigate();

	if (!repost) return null;
	return (
		<RepostBox onClick={() => {
			if (!disableClick) navigate(`/post/${repost.post.postId}`);
		}}>
			<RepostCloseFlex>
				<RepostUser>
					<img alt="" src={getPfp(repost.author.display_name, repost.author.pfp)} />

					<p>
						{repost.author.display_name} • <span>@{repost.author.username}</span>
					</p>
				</RepostUser>

				{/* When it's used inside of a (post)creator, it will display an X to show how to close it. */}
				{disableClick && <RepostClose>
					<HiOutlineX color="#500000" onClick={() => navigate("/")} size={20} />
					</RepostClose>}
			</RepostCloseFlex>

			<RepostContent>{formatText(repost.post.content)}</RepostContent>

			<PostAttachments post={repost.post} />

			<RepostComments>{repost.post.likes.length} likes • {repost.post.commentCount} comments</RepostComments>
		</RepostBox>
	)
}