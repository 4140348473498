import { toast } from "react-toastify";
import { CDN_URL } from "./constants";

export const uploadImage = async (image: any) => {
	let imageUpload;
	if (image.data !== '') {
		//@ts-ignore
		if (image.data.type !== "image/png" && image.data.type !== "image/jpeg" && image.data.type !== "image/jpg") {
			toast.error("Please provide a valid image. (.png, .jpg or .jpeg)")
			return false;
		}
		const formData = new FormData();
		formData.append('file', image.data);

		// Upload the image
		const pfpResponse: any = await fetch(CDN_URL + '/upload-image', {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': document.cookie ? document.cookie.split(';').filter((s: string) => s.includes('token'))[0].split('=')[1] : ''
			}
		});
		if (pfpResponse.status === 500) {
			toast.error('The upload failed. You can only upload files up to 5MB.');
			return false;
		}

		const jsonRes = await pfpResponse.json();

		// Check if the file uploaded.
		if (jsonRes.error) {
			toast.error(jsonRes.error);
			return false;
		}
		if (jsonRes && jsonRes.file && !jsonRes.file.filename) {
			toast.error('The upload failed.');
			return false;
		}

		imageUpload = jsonRes.file.filename;
	}
	return imageUpload;
}

export const uploadVideo = async (video: any) => {
	let videoUpload;

	if (video.data !== '') {
		//@ts-ignore
		if (video.data.type !== "video/mp4" && video.data.type !== "video/mov") {
			toast.error("Please provide a valid video. (.mp4 of mov)")
			return false;
		}

		const formData = new FormData();
		formData.append('file', video.data);

		//* Upload the image
		const pfpResponse: any = await fetch(CDN_URL + '/upload-video', {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': document.cookie ? document.cookie.split(';').filter((s: string) => s.includes('token'))[0].split('=')[1] : ''
			}
		});
		if (pfpResponse.status === 500) {
			toast.error('The upload failed. You can only upload files up to 5MB.');
			return false;
		}

		const jsonRes = await pfpResponse.json();

		// Check if the file uploaded.
		if (jsonRes.error) {
			toast.error(jsonRes.error);
			return false;
		}
		if (jsonRes && jsonRes.file && !jsonRes.file.filename) {
			toast.error('The upload failed.');
			return false;
		}

		videoUpload = jsonRes.file.filename;
	}

	return videoUpload;
}

export const uploadDocument = async (document: any) => {
	let attachmentUpload;
	if (document.data !== '') {
		const formData = new FormData();
		formData.append('file', document.data);

		// Upload the image
		const pfpResponse: any = await fetch(CDN_URL + '/upload-document', {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': document.cookie ? document.cookie.split(';').filter((s: string) => s.includes('token'))[0].split('=')[1] : ''
			}
		});
		
		if (pfpResponse.status === 500) {
			toast.error('The upload failed. You can only upload files up to 5MB.');
			return false;
		}

		const jsonRes = await pfpResponse.json();

		// Check if the file uploaded.
		if (jsonRes.error) {
			toast.error(jsonRes.error);
			return false;
		}
		if (jsonRes && jsonRes.file && !jsonRes.file.filename) {
			toast.error('The upload failed.');
			return false;
		}

		attachmentUpload = jsonRes.file.filename;
	}
	return attachmentUpload;
}