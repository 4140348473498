import { useNavigate } from "react-router";
import reactStringReplace from "react-string-replace";

export const formatText = (text: string | undefined) => {
    let replacedText;


    //* Italic
    replacedText = reactStringReplace(text, /\*(.*?)\*/gm, (match, i) => (
        <span
            style={{ color: "black", fontStyle: 'italic' }}
            key={match + i}
        >
            {match}
        </span>
    ));

    //* Url
    replacedText = reactStringReplace(replacedText, /(https?:\/\/\S+)/g, (match, i) => (
        <span
            onClick={(e) => {
                e.stopPropagation();
                window.location.href = match;
            }}
            className="link-style"
            style={{
                color: "#1d9bf0",
                cursor: 'pointer'
            }}
            key={match + i}
        >
            {match}
        </span>
    ));

    //* Bold
    replacedText = reactStringReplace(replacedText, /\*\*(.*?)\*\*/gm, (match, i) => (
        <span
            style={{ color: "black", fontWeight: '600' }}
            key={match + i}
        >
            {match}
        </span>
    ));

    //* Code
    replacedText = reactStringReplace(replacedText, /\`(.*?)\`/gm, (match, i) => (
        <span
            style={{ color: "white", backgroundColor: '#3a3a3a', padding: '0px 2px', fontFamily: 'monospace', borderRadius: '2px' }}
            key={match + i}
        >
            {match}
        </span>
    ));

    replacedText = reactStringReplace(replacedText, /@(\w+)/g, (match, i) => (
        <span
            className="mention-style"
            style={{ color: "#1d9bf0", cursor: 'pointer' }}
            key={match + i}
            onClick={() => window.location.href = `/@${match}`}
        >
            @{match}
        </span>
    ));

    replacedText = reactStringReplace(replacedText, /\W(\#[a-zA-Z]+\b)(?!;)/gm, (match, i) => (
        <span
            className="hastag-style"
            style={{ color: "#1d9bf0", cursor: 'pointer' }}
            key={match + i}
            onClick={() => window.location.href = `/search?tag=${match.replaceAll("#", "")}`}
        >
            {match}
        </span>
    ));

    replacedText = reactStringReplace(replacedText, /\_\_(.*?)\_\_/gm, (match, i) => (
        <span
            style={{ color: "black", textDecoration: 'underline' }}
            key={match + i}
        >
            {match}
        </span>
    ));

    return replacedText;
}

export function formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}