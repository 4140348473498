//* Post Renderer
import { useState } from "react";
import { PostWithAuthor } from "../../utils/types";
import { Content, LastEdited, NameWrapper, PostBox } from "../../styles/components/posts/Post";
import moment from "moment";
import { PostUsername } from "./root/Username";
import { PostDropdown } from "./root/Dropdown";
import { PostAttachments } from "./root/Attachments";
import { PostRepost } from "./root/Reposts";
import { PostFooter } from "./root/IconsBar";
import { EditingPost } from "./root/EditingPost";
import { formatText } from "../../utils/Markdown";

type Props = {
	post: PostWithAuthor;
	setRepostId?: (id: string) => void;
	disableActions?: boolean;
};

export const PostDisplay = ({ post, setRepostId, disableActions }: Props) => {

	const [editing, setEditing] = useState(false);

	if (editing) return <EditingPost post={post} setEditing={setEditing} />
	return (
		<PostBox>
			<NameWrapper>
				<PostUsername user={post.author} post={post.post} />

				<PostDropdown post={post} setRepostId={setRepostId} disableActions={disableActions} setEditing={(e) => setEditing(e)} />
			</NameWrapper>

			<Content>{formatText(post.post.content)}</Content>
			{post.post.edited !== 0 && <LastEdited data-tippy-content={moment(post.post.edited).format("dddd, MMMM Do YYYY, h:mm:ss a")}>(last edited {moment(post.post.edited).fromNow()})</LastEdited>}

			<PostAttachments post={post.post} />

			<PostRepost repost={post.repost} />

			<PostFooter post={post} setRepostId={setRepostId} />
		</PostBox>
	)
}