//* Flags Renderer Component
import styled from "styled-components";

import Verified from "../../assets/icons/verified.svg";
import { HiMusicalNote, HiWrench } from "react-icons/hi2";
import { GiMapleLeaf } from "react-icons/gi";


//* Styles for the Flags Renderer
const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;


type Props = {
	flags: string[];
};

export const FlagsRenderer = ({ flags }: Props) => {
	return (
		<Wrapper>
				{flags.includes("verified") && <img src={Verified} alt="Verified Badge" />}
				{flags.includes("developer") && <HiWrench style={{ marginLeft: '5px' }} color="var(--accent)" />}
				{flags.includes("music") && <HiMusicalNote style={{ marginLeft: '5px' }} color="var(--accent)" />}
		</Wrapper>
	)
}