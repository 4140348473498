import { useEffect, useState } from "react";

const delay = 2500; //2.5s
let setTimeoutInstance: any;

type Props = {
	loading: boolean;
	children: any;
}
export const  Loader = ({ loading, children }: Props) => {
	const [isExpired, setIsExpired] = useState(true);

	useEffect(() => {
		if (loading) {
			setIsExpired(false);

			if (setTimeoutInstance) {
				clearTimeout(setTimeoutInstance);
			}
			setTimeoutInstance = setTimeout(() => {
				setIsExpired(true);
			}, delay);
		}
	}, [loading]);

	if (!isExpired) {
		return <></>;
	}

	return children;
};