import { useNavigate } from "react-router";
import { PageContainer, PageTitle } from "../../styles/General";
import { Button, Input, InputText, LoginContainer } from "../../styles/pages/Login";
import { useContext, useState } from "react";
import { Context } from "../../utils/Context";
import { BoxContainer } from "../../styles/pages/Settings";
import { updatePassword } from "../../api/USER";
import { HiOutlineEye } from "react-icons/hi";
import { hashPassword, testPassword } from "../../utils/constants";
import { toast } from "react-toastify";

export const ChangePassword = () => {

	const navigate = useNavigate();

	const { setUser, user, loading: loadingUser } = useContext(Context);

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');

	const [loading, setLoading] = useState(false);


	const save = async () => {
		if (!user) return;
		if (loading) return;
		if (!currentPassword || !newPassword) return;

		if (!testPassword(newPassword)) return toast.error("Please enter a valid new password, it must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character");

		setLoading(true);
		updatePassword(currentPassword, await hashPassword(newPassword))
			.then(({ data }) => {
				toast.success('Password updated!');
				setUser(data);
				setLoading(false);

				navigate('/settings');
			})
			.catch((err) => {
				setLoading(false);
				return toast.error(err.response.data ?? err.response.data.error);
			});
	}

	// Password visibility
	function togglePw(id: string) {
		var passwordBox: any = document.getElementById(id);
		console.log(passwordBox)
		if (passwordBox.type === "password") { passwordBox.type = "text"; } else { passwordBox.type = "password"; }
	}

	if (!user && !loadingUser) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>Change Password</PageTitle>
				<BoxContainer>
					<InputText>Current Password</InputText>
					<div>
						<Input onInput={(e) => setCurrentPassword(e.currentTarget.value)} id="cur-password" placeholder="Current Password" type="password" />
						<HiOutlineEye onClick={() => togglePw("cur-password")} style={{ marginLeft: '-25px', marginTop: '9px', cursor: 'pointer', position: 'absolute' }} />
					</div>

					<InputText>New Password</InputText>
					<div>
						<Input onInput={(e) => setNewPassword(e.currentTarget.value)} id="new-password" placeholder="Current Password" type="password" />
						<HiOutlineEye onClick={() => togglePw("new-password")} style={{ marginLeft: '-25px', marginTop: '9px', cursor: 'pointer', position: 'absolute' }} />
					</div>

					<Button disabled={loading} onClick={save} href="#">Update Password</Button>
				</BoxContainer>
			</LoginContainer>
		</PageContainer>
	);
}