import { useState } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const Banner = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 100px);
    background-color: var(--accent);
    color: #fff;
    padding: 22px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10000;

    @media (max-width: 768px) {
        display: block;
        padding: 10px 20px;
        width: calc(100% - 40px);
    }
`;

const Title = styled.p`
    color: #FFF;
    display: flex;
    align-items: center;
    svg { margin-left: 15px; cursor: pointer; }
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    margin: 0px;
    margin-bottom: 7px;
    line-height: normal; 
`;

const Message = styled.p`
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    line-height: normal;
`;

const ButtonFill = styled.a`
    background-color: white;
    color: var(--accent);
    padding: 8px 25px;
    width: fit-content;
    margin: 0px;
    margin-right: 18px;
    border-radius: 20px;

    :hover {
        color: var(--accent-hover);
    }
`;

const ButtonBorder = styled.a`
    border: 1px solid white;
    width: fit-content !important;
    color: white;
    padding: 8px 25px;
    margin: 0px;
    border-radius: 20px;

    :hover {
        color: #fff;
        border: 1px solid #fff;
        background-color: var(--accent-hover);
    }
`;

const ButtonWrapper = styled.div`
    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

export const NotLoggedInBanner = () => {

    const navigate = useNavigate();

    const [show, setShow] = useState(true);

    return (
        <>
            {show && <Banner>
                <div>
                    <Title>Not logged in! <HiOutlineX onClick={() => setShow(false)} /></Title>
                    <Message>Log in to participate in the conversation, like posts and more!</Message>
                </div>

                <ButtonWrapper>
                    <ButtonFill onClick={() => navigate('/login')} href="#">Log In</ButtonFill>
                    <ButtonBorder onClick={() => navigate('/register')} href='#'>Sign Up</ButtonBorder>
                </ButtonWrapper>
            </Banner>}
        </>
    );
}