import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Context } from "../../utils/Context";
import { PageContainer, PageTitle } from "../../styles/General";
import { LoginContainer, NoteText } from "../../styles/pages/Login";
import { checkVerificationCode } from "../../api/USER";
import { toast } from "react-toastify";

export const VerifyEmail = () => {

	const navigate = useNavigate();

	const { setUser, user, loading:loadingSelf } = useContext(Context);

	const [loading, setLoading] = useState(true);


	const queryParameters = new URLSearchParams(window.location.search)
	const code = queryParameters.get("code")

	useEffect(() => {
		if (!user) return;
		checkVerificationCode(code ?? "")
			.then(({ data }) => {
				if (user?.userId !== data.userId) return toast.error("Invalid code!");
				setUser({ ...user, verified: true });
				toast.success("Email verified!");
				setLoading(false);
				navigate('/');
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
				toast.error(err.response.data ?? err.response.data.error);
				navigate('/settings');
				return;
			});
	}, [loadingSelf])


	if (!user && !loadingSelf) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>Email verification</PageTitle>
					{loading && <NoteText>Checking code...</NoteText>}
					{!loading && <NoteText>Redirecting...</NoteText>}
			</LoginContainer>
		</PageContainer>
	);
}