import { CommentWithAuthor } from "../../../utils/types";
import Liked from "../../../assets/icons/liked.svg";
import Unliked from "../../../assets/icons/unliked.svg";
import Reply from "../../../assets/icons/reply.svg";
import { useContext, useState } from "react";
import { Context } from "../../../utils/Context";
import { ActionButton, IconsBar } from "../../../styles/components/posts/Post";
import { toast } from "react-toastify";
import { likeComment } from "../../../api/COMMENT";
import { CommentLikesPopup } from "../CommentLikesPopup";
import { HiHeart, HiOutlineHeart } from "react-icons/hi2";
import { HiOutlineReply } from "react-icons/hi";

type Props = {
	comment: CommentWithAuthor;
	setReplyId: (id: string) => void;
	comments: CommentWithAuthor[];
	setComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
}
export const CommentFooter = ({ comment, setReplyId, comments, setComments }: Props) => {

	const { user } = useContext(Context);

	const [liking, setLiking] = useState(false);
	const like = () => {
		if (liking) return;
		if (!user) return toast.error("You are not logged in.");

		setLiking(true);

		const likes = comment.comment.likes;

		if (likes.includes(user.userId)) {
			likes.splice(likes.indexOf(user.userId), 1);
		} else {
			likes.push(user.userId);
		}

		setComments([...comments]);

		likeComment(comment.comment.postId, comment.comment.commentId)
			.then(({ data }) => {
				comments[comments.findIndex((p) => p.comment.commentId === comment.comment.commentId)].comment.likes = data.likes;
				setComments([...comments]);
				setLiking(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.response.data.error);
				setLiking(false);
			});
	}

	const reply = () => {
		if (!user) return toast.error("You are not logged in.");
		setReplyId(comment.comment.commentId);
		window.scrollTo({ top: 100, behavior: 'smooth' });
	}

	const [popup, setPopup] = useState(false);

	return (
		<>
			<IconsBar style={{ paddingTop: '0px' }}>
				<ActionButton saving={liking}>
					{user && comment.comment.likes.includes(user.userId) && <HiHeart onClick={() => like()} color="red" size={18} />}
					{(!user || (user && !comment.comment.likes.includes(user.userId))) && <HiOutlineHeart onClick={() => like()} size={18} />}

					{comment.comment.likes.length} <span onClick={() => setPopup(true)}>likes</span>
				</ActionButton>

				<ActionButton onClick={reply}>
					<HiOutlineReply size={18} />
					<span>Reply</span>
				</ActionButton>
			</IconsBar>

			{popup && <CommentLikesPopup comment={comment.comment} setPopup={setPopup} />}
		</>
	)
}