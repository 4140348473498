import { useNavigate } from "react-router";
import { PageContainer, PageTitle } from "../../styles/General";
import { Button, Input, InputText, LoginContainer } from "../../styles/pages/Login";
import { useContext, useState } from "react";
import { Context } from "../../utils/Context";
import { BoxContainer } from "../../styles/pages/Settings";
import { updateEmailAdress } from "../../api/USER";
import { testEmail } from "../../utils/constants";
import { toast } from "react-toastify";

export const ChangeEmail = () => {

	const navigate = useNavigate();

	const { setUser, user, loading: loadingUser } = useContext(Context);

	const [currentEmail, setCurrentEmail] = useState('');
	const [newEmail, setNewEmail] = useState('');

	const [loading, setLoading] = useState(false);
	const disabled = user?.flags.includes('google');


	const save = () => {
		if (disabled) return;
		if (!user) return;
		if (loading) return;
		if (!currentEmail || !newEmail) return;

		if (!testEmail(newEmail)) return toast.error("Please enter a valid email address");
		if (currentEmail !== user.email) return toast.error("Current email does not match your email");

		setLoading(true);
		updateEmailAdress(currentEmail, newEmail)
			.then(({ data }) => {
				toast.success('Email updated!');
				setUser(data);
				setLoading(false);

				navigate('/settings');
			})
			.catch((err) => {
				setLoading(false);
				return toast.error(err.response.data ?? err.response.data.error);
			});
	}


	if (!user && !loadingUser) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>Change Email</PageTitle>
				<BoxContainer>
					<InputText>Current Email</InputText>
					<Input id="email" onChange={(e) => setCurrentEmail(e.currentTarget.value)} placeholder="Current Email" />

					<InputText>New Email</InputText>
					<Input id="new-email" onChange={(e) => setNewEmail(e.currentTarget.value)} placeholder="New Email" />

					<Button disabled={loading || disabled} onClick={save} href="#">Update Email</Button>
				</BoxContainer>
			</LoginContainer>
		</PageContainer>
	);
}