import { PostWithAuthor } from "../../../utils/types";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/Context";
import { ActionButton, IconsBar } from "../../../styles/components/posts/Post";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { bookmarkPost, likePost } from "../../../api/POST";
import { LikesPopup } from "../LikesPopup";
import { HiBookmark, HiHeart, HiOutlineArrowPathRoundedSquare, HiOutlineBookmark, HiOutlineChatBubbleLeft, HiOutlineHeart } from "react-icons/hi2";

type Props = {
	post: PostWithAuthor;
	setRepostId?: (id: string) => void;
}
export const PostFooter = ({ post, setRepostId }: Props) => {

	const { user, bookmarks, posts, setPosts, followingPosts, setFollowingPosts, topPosts, setTopPosts, setBookmarks } = useContext(Context);
	const navigate = useNavigate();

	const [liking, setLiking] = useState(false);
	const like = () => {
		if (liking) return;
		if (!user) return toast.error("You are not logged in.");

		setLiking(true);

		const likes = post.post.likes;

		if (likes.includes(user.userId)) {
			likes.splice(likes.indexOf(user.userId), 1);
		} else {
			likes.push(user.userId);
		}

		setPosts([...posts]);

		toast.promise(
			likePost(post.post.postId)
				.then(({ data }) => {
					if (posts[posts.findIndex((p) => p.post.postId === post.post.postId)]) posts[posts.findIndex((p) => p.post.postId === post.post.postId)].post.likes = data.likes;
					if (followingPosts[followingPosts.findIndex((p) => p.post.postId === post.post.postId)]) followingPosts[followingPosts.findIndex((p) => p.post.postId === post.post.postId)].post.likes = data.likes;
					if (topPosts[topPosts.findIndex((p) => p.post.postId === post.post.postId)]) topPosts[topPosts.findIndex((p) => p.post.postId === post.post.postId)].post.likes = data.likes;

					setFollowingPosts([...followingPosts]);
					setTopPosts([...topPosts]);
					setPosts([...posts]);
					setLiking(false);
				}), {
			pending: 'Saving...',
			success: 'Liked!',
			error: {
				render() {
					setLiking(false);
					return 'Failed to (un)like the post.'
				},
			}
		});
	}


	const [bookmarked, setBookmarked] = useState(false);
	const [bookmarking, setBookmarking] = useState(false);
	const bookmark = () => {
		if (!user) return toast.error("You are not logged in.");
		if (bookmarking) return;

		if (bookmarked) setBookmarked(false);
		else setBookmarked(true);

		setBookmarking(true);
		toast.promise(
			bookmarkPost(post.post.postId)
				.then(({ data }) => {
					setBookmarks(data.bookmarks);
					setBookmarking(false);
				}), {
			pending: 'Saving...',
			success: 'Saved!',
			error: {
				render() {
					setBookmarking(false);
					setBookmarked(bookmarks.includes(post.post.postId));
					return 'Failed to (un)save the post.'
				},
			}
		});
	}

	//* Unbookmark the bookmarks if the post is removed from the list.
	useEffect(() => {
		if (user && bookmarks.includes(post.post.postId)) setBookmarked(true);
		else setBookmarked(false);
	}, [user, bookmarks, post.post.postId]);


	const repostPost = (postId: string) => {
		if (!user) return toast.error("You are not logged in.");
		if (setRepostId) setRepostId(postId);

		window.scrollTo({ top: 0, behavior: 'smooth' });

		const creator = document.getElementById("msg-box") as HTMLInputElement;
		if (creator) {
			creator.focus();
			creator.setSelectionRange(0, 0);
		}
	}

	const [popup, setPopup] = useState(false);

	return (
		<>
			<IconsBar>
				<ActionButton saving={liking}>
					{user && post.post.likes.includes(user.userId) && <HiHeart onClick={() => like()} color="red" size={18} />}
					{(!user || (user && !post.post.likes.includes(user.userId))) && <HiOutlineHeart onClick={() => like()} size={18} />}

					{post.post.likes.length} <span onClick={() => setPopup(true)}>likes</span>
				</ActionButton>

				<ActionButton onClick={() => navigate(`/post/${post.post.postId}`)}>
					<HiOutlineChatBubbleLeft size={18} />
					{post.post.commentCount}<span>comments</span>
				</ActionButton>

				{setRepostId && <ActionButton onClick={() => repostPost(post.post.postId)}>
					<HiOutlineArrowPathRoundedSquare size={18} />
					<span>Repost</span>
				</ActionButton>}

				<ActionButton onClick={() => bookmark()} saving={bookmarking}>
					{bookmarked && <HiBookmark color="var(--accent)" size={18} />}
					{!bookmarked && <HiOutlineBookmark size={18} />}
					<span>Save{bookmarked && 'd'}</span>
				</ActionButton>
			</IconsBar>
			{popup && <LikesPopup post={post.post} setPopup={setPopup} postId={post.post.postId} />}
		</>
	)
}