import styled from 'styled-components';
import { API_URL, CDN_URL } from '../../utils/constants';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000ae;
    display: grid;
    place-items: center;
    z-index: 100000;
`;

const Image = styled.img`
    max-width: 80%;
    max-height: 80%;
`;

const Close = styled.button`
    border: none;
    background: none;
    border-radius: 100px;
    padding: 5px;
    display: grid;
    place-items: center;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 25px;
    color: white;
`;

type Props = {
    image?: string;
    setShown: () => void;
};

export const AttachmentPoup = ({ image, setShown }: Props) => {

    //* Close with ESC key
    const closeWithEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') setShown();
    }
    window.addEventListener('keydown', closeWithEsc);

    return (
        <Wrapper onClick={(e) => {
            // Close if clicked outside of image
            const target = e.target as HTMLElement;
            if (target.id === 'attachment-display') return;
            setShown();
        }}>
            <Close onClick={setShown}>&times;</Close>
            {image && <Image id='attachment-display' src={CDN_URL + '/' + image} />}
        </Wrapper>
    )
}