import styled from "styled-components";
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem } from '@mui/base/MenuItem';

export const PageContainer = styled.div`
	margin: 0px auto;
	max-width: calc(100% - 100px);
	padding: 0px 10px;

	@media (max-width: 600px) {
		padding: 0px 10px;
		max-width: calc(100% - 20px);
	}
`;

export const PageTitle = styled.p`
	color: var(--text-color);
	font-family: Inter;
	font-size: 50px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

export const Box = styled.div`
	background-color: var(--navbar-color);
	border-radius: 5px;
	box-shadow: 0px 2px 4px var(--box-shadow);
	padding: 20px;
`;

export const Listbox = styled('ul')`
	z-index: 0;
	box-sizing: border-box;
	margin: 5px 0;
	padding: 3px;
	font-family: 'Inter', sans-serif;
	min-width: 115px;
	border-radius: 8px;
	overflow: auto;
	font-weight: 500;
	outline: 0px;
	background: var(--background-color);
	color: var(--text-color);
	box-shadow: 0px 4px 4px 0px var(--box-shadow);
	z-index: 1;
`;

export const MenuItem = styled(BaseMenuItem)`
	list-style: none;
	padding: 10px;
	cursor: pointer;
	background-color: var(--background-color);
	border-radius: 5px;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 400;

	svg {
		margin-right: 10px;
	}

	&:last-child {
		border-bottom: none;
	}
  
	&:hover {
	  background: var(--navbar-color);
	}
`;

export const MenuButton = styled(BaseMenuButton)`
	background: none;
	border: none;
	cursor: pointer;
`;

export const EmojiPickerWrapper = styled.div`
	position: absolute;
	margin-top: 10px;
	z-index: 500;
`;