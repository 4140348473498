import { useRef, useEffect, useState } from "react";
import { searchMorePosts, searchPosts } from "../api/POST";
import { HomeContainer, HomeWrapper, PostsContainer } from "../styles/pages/Home";
import { Userbar } from "../components/sidebar/Userbar";
import { PostDisplay } from "../components/posts/Post";
import { RecommendedBar } from "../components/sidebar/RecommendedBar";
import { useNavigate } from "react-router";
import { OtherUser, PostWithAuthor } from "../utils/types";
import { toast } from "react-toastify";
import { Displayname, Pfp, SearchBar, SearchBox, SearchTabs, SearchedUsersBox, User, Username } from "../styles/pages/Search";
import { PostLoadingSkeleton } from "../components/loading/Post";
import { HiOutlineSearch } from "react-icons/hi";
import { searchUsers } from "../api/USER";
import { getPfp } from "../utils/constants";
import { FlagsRenderer } from "../components/root/FlagsRenderer";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { Loader } from "../components/root/RetryLoad";

export const SearchPage = () => {

    const [searchParams] = useSearchParams();

    const [query, setQuery] = useState(searchParams.get("tag") ? `#${searchParams.get("tag")}` : "");
    const [tab, setTab] = useState("posts");



    const [posts, setPosts] = useState<PostWithAuthor[]>([]);
    const [users, setUsers] = useState<OtherUser[]>([]);
    const [loadingPosts, setLoadingPosts] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const navigate = useNavigate();

    const search = () => {
        if (query === "" || !query) {
            setPosts([]);
            setUsers([]);
            setLoadingPosts(false);
            setLoadingUsers(false);

            return;
        }

        if (tab === "posts") {
            setLoadingPosts(true);

            searchPosts(query)
                .then(({ data }) => {
                    setPosts(data);
                })
                .catch((err) => {
                    console.log('Failed to load response: ' + err.response.data.error);
                    toast.error(err.response.data.error);
                })
                .finally(() => setLoadingPosts(false));
        } else {
            setLoadingUsers(true);
            setUsers([]);

            searchUsers(query)
                .then(({ data }) => {
                    setUsers(data);
                })
                .catch((err) => {
                    console.log('Failed to load response: ' + err.response.data.error);
                    toast.error(err.response.data.error);
                })
                .finally(() => setLoadingUsers(false));
        }
    };

    useEffect(() => {
        setQuery(searchParams.get("tag") ? `#${searchParams.get("tag")}` : "");
        const searchBox = document.getElementById('search-box') as HTMLInputElement;
        searchBox.focus();
        searchBox.value = searchParams.get("tag") ? `#${searchParams.get("tag")}` : "";

        search();        
    }, [searchParams]);

    window.onscroll = function () { checkScroll(); };

    const [loadingMore, setLoadingMore] = useState(false);
    const [loadedAll, setLoadedAll] = useState(false);
    const checkScroll = async () => {

        const percentage = Math.floor(document.documentElement.scrollTop / (document.body.offsetHeight - window.innerHeight) * 100);

        if (tab === "posts") {
            if (percentage > 80 && posts && !loadingMore && !loadedAll) {
                setLoadingMore(true);
    
                const { data: newPosts } = await searchMorePosts(query, posts[posts.length - 1].post.date);
                if (!newPosts) return setLoadingMore(false);
                if (!newPosts[0]) {
                    setLoadingMore(false);
                    setLoadedAll(true);
                    return;
                }
                if (newPosts[0].post.postId !== posts[posts.length - 1].post.postId) setPosts(posts.concat(newPosts));
    
                setLoadingMore(false);
            }
        }
    }

    document.onkeydown = (e) => {
        if (e.key === 'Enter') search();
    }

    return (
        <>
            <HomeWrapper>
                <HomeContainer>
                    <Userbar />

                    <PostsContainer>
                        <SearchBar>
                            <input id="search-box" onInput={(e) => setQuery(e.currentTarget.value)} defaultValue={query} placeholder="Search..." />
                            <HiOutlineSearch onClick={() => search()} />
                        </SearchBar>

                        <SearchTabs>
                            <SearchBox active={tab === 'posts'} onClick={() => {
                                setTab('posts');
                                const searchBox = document.getElementById('search-box') as HTMLInputElement;
                                searchBox.focus();
                                searchBox.value = "";
                            }}>Posts</SearchBox>

                            <SearchBox active={tab === 'users'} onClick={() => {
                                setTab('users');
                                const searchBox = document.getElementById('search-box') as HTMLInputElement;
                                searchBox.focus();
                                searchBox.value = "";
                            }}>Users</SearchBox>
                        </SearchTabs>

                        {tab === 'posts' && <div>

                            {posts.length === 0 && !loadingPosts && <SearchedUsersBox>No posts found.</SearchedUsersBox>}
                            {loadingPosts && [...Array(30)].map((_, i) => <PostLoadingSkeleton />)}

                            {!loadingPosts && posts && posts.map((p) => <PostDisplay disableActions post={p} />)}

                            {loadingMore && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <div style={{ margin: '10px auto', width: 'fit-content' }}><MoonLoader size={25} /></div>

                            <Loader loading={loadingMore} children={
                                <p style={{ cursor: 'pointer', fontSize: '15px', margin: '15px 0px', color: '#4e7baa' }} onClick={() => {
                                    setLoadingMore(false);
                                    checkScroll();
                                }}>Taking a bit long to load more posts? Click here to try again!</p>} />

                        </div>}

                        {loadedAll && <div style={{ margin: '10px auto', width: 'fit-content' }}>
                            <p style={{ color: 'rgba(0, 0, 0, 0.50)' }}>You've reached the end. You've seen it all.</p>
                        </div>}
                        </div>}

                        {tab === 'users' && <SearchedUsersBox>
                            {users.length === 0 && !loadingUsers && <span>No users found.</span>}

                            {!loadingUsers && users && users.map((u) => <User onClick={() => navigate(`/@${u.username}`)}>
                                <Pfp src={getPfp(u.display_name, u.pfp)} alt="" />

                                <div>
                                    <Displayname>{u.display_name} <FlagsRenderer flags={u.flags} /></Displayname>
                                    <Username>@{u.username}</Username>
                                </div>
                            </User>)}

                            {loadingUsers && [...Array(5)].map(() => <User>
                                <Skeleton style={{ marginRight: '15px' }} width={40} height={40} borderRadius={50} />

                                <div>
                                    <Displayname><Skeleton width={60} /></Displayname>
                                    <Username><Skeleton width={100} /></Username>
                                </div>
                            </User>)}
                        </SearchedUsersBox>}

                    </PostsContainer>

                    <RecommendedBar />
                </HomeContainer>
            </HomeWrapper>
        </>
    );
};