import { useNavigate } from "react-router";
import { PageContainer, PageTitle } from "../../styles/General";
import { Button, Input, InputText, LoginContainer } from "../../styles/pages/Login";
import { useContext, useState } from "react";
import { Context } from "../../utils/Context";
import { BoxContainer } from "../../styles/pages/Settings";
import { deleteAccount } from "../../api/USER";
import { HiOutlineEye } from "react-icons/hi";
import { toast } from "react-toastify";

export const DeleteAccount = () => {

	const navigate = useNavigate();

	const { setUser, user, loading: loadingUser } = useContext(Context);

	const [currentPassword, setCurrentPassword] = useState('');

	const [loading, setLoading] = useState(false);


	const save = async () => {
		if (!user) return;
		if (loading) return;
		if (!currentPassword && !user.flags.includes('google')) return;

		setLoading(true);
		deleteAccount(currentPassword)
			.then(({ data }) => {
				toast.success('Account Deleted!');
				setUser(null);
				setLoading(false);

				window.location.href = '/';
			})
			.catch((err) => {
				setLoading(false);
				return toast.error(err.response.data);
			});
	}

	// Password visibility
	function togglePw(id: string) {
		var passwordBox: any = document.getElementById(id);
		console.log(passwordBox)
		if (passwordBox.type === "password") { passwordBox.type = "text"; } else { passwordBox.type = "password"; }
	}

	if (!user && !loadingUser) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>⚠️ Delete Account ⚠️</PageTitle>
				<BoxContainer>
					{!user?.flags.includes('google') && <><InputText>Password</InputText>
						<div>
							<Input onInput={(e) => setCurrentPassword(e.currentTarget.value)} id="password" placeholder="Password" type="password" />
							<HiOutlineEye onClick={() => togglePw("password")} style={{ marginLeft: '-25px', marginTop: '9px', cursor: 'pointer', position: 'absolute' }} />
						</div>
						</>}

					<Button disabled={loading} onClick={save} href="#">⚠️ Delete Account - Cannot be undone ⚠️</Button>
				</BoxContainer>
			</LoginContainer>
		</PageContainer>
	);
}