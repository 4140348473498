import { useContext, useEffect, useState } from "react";
import { CommentWithAuthor, PostWithAuthor } from "../../../utils/types";
import { getPfp } from "../../../utils/constants";
import { CommentBox, CommentContent, CommentUserWrapper, Pfp } from "../../../styles/components/comments/Comment";
import { CommentUser } from "./Username";
import { CommentDropdown } from "./Dropdown";
import { EditingButton, EditingTextarea } from "../../../styles/components/posts/Post";
import { Context } from "../../../utils/Context";
import { toast } from "react-toastify";
import { editComment } from "../../../api/COMMENT";


type Props = {
	comment: CommentWithAuthor;
	setComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>; // This is a function that takes a new state and updates the state with it
	topComments: CommentWithAuthor[];
	setTopComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
	followingComments: CommentWithAuthor[];
	setFollowingComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
	comments: CommentWithAuthor[];
	post: PostWithAuthor;
	setReplyId: React.Dispatch<React.SetStateAction<string>>;
	setEditing: (editing: boolean) => void;
};

export const EditingComment = ({ comment, setEditing, setComments, comments, post, setReplyId, topComments, setTopComments, followingComments, setFollowingComments }: Props) => {

	const [content, setContent] = useState(comment.comment.content);

	useEffect(() => {
		setContent(comment.comment.content);
	}, [comment]);

	const [saving, setSaving] = useState(false);
	const { user } = useContext(Context);

	const editCommentFunction = () => {
		if (saving) return;
		if (!user) return toast.error("You are not logged in.");

		setSaving(true);
		editComment(comment.comment.postId, comment.comment.commentId, content)
			.then(({ data }) => {
				if (comments[comments.findIndex((c) => c.comment.commentId === comment.comment.commentId)]) comments[comments.findIndex((c) => c.comment.commentId === comment.comment.commentId)].comment.content = data.content;
				if (comments[comments.findIndex((c) => c.comment.commentId === comment.comment.commentId)]) comments[comments.findIndex((c) => c.comment.commentId === comment.comment.commentId)].comment.edited = data.edited;
				setComments([...comments]);

				if (followingComments[followingComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)]) followingComments[followingComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)].comment.content = data.content;
				if (followingComments[followingComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)]) followingComments[followingComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)].comment.edited = data.edited;
				setFollowingComments([...followingComments]);

				if (topComments[topComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)]) topComments[topComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)].comment.content = data.content;
				if (topComments[topComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)]) topComments[topComments.findIndex((c) => c.comment.commentId === comment.comment.commentId)].comment.edited = data.edited;
				setTopComments([...topComments]);
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.response.data.error);
			})
			.finally(() => {
				setSaving(false);
				setEditing(false);
			})
	}

	return (
		<CommentBox>
			<Pfp src={getPfp(comment.author.display_name, comment.author.pfp)} />

			<CommentContent>
				<CommentUserWrapper>
					<CommentUser comment={comment} op={comment.author.username === post.author.username} />

					<CommentDropdown comment={comment} comments={comments} followingComments={followingComments} post={post} setComments={setComments} setEditing={setEditing} setFollowingComments={setFollowingComments} setTopComments={setTopComments} topComments={topComments} />
				</CommentUserWrapper>


				<EditingTextarea value={content} onChange={(e) => setContent(e.currentTarget.value)} />

				<EditingButton onClick={() => setEditing(false)}>Cancel</EditingButton>
				<EditingButton onClick={() => editCommentFunction()}>Save</EditingButton>
			</CommentContent>
		</CommentBox>
	)
}