import { AttachmentWrapper, DocumentWrapper, Image, RemoveWrapper, Video } from "../../../styles/components/posts/Post";
import { HiXMark } from "react-icons/hi2";
import { formatBytes } from "../../../utils/Markdown";

type Props = {
	image: any;
	video: any;
	attachment: any;
	removeAttachment: () => void;
	removeImage: () => void;
	removeVideo: () => void;
}
export const PostCreatorAttachments = ({ image, video, attachment, removeImage, removeVideo, removeAttachment }: Props) =>
	<>
		<AttachmentWrapper style={{ display: 'flex'}}>
			{image && <Image src={image} />}
			{image && <RemoveWrapper><HiXMark onClick={removeImage} color="#b33939" size={16} /></RemoveWrapper>}

			{video && <Video src={video} controls />}
			{video && <RemoveWrapper><HiXMark onClick={removeVideo} color="#b33939" size={16} /></RemoveWrapper>}

			{attachment.preview && <DocumentWrapper>
				<div>
					<b>{attachment.data.name}</b>
					<p>{formatBytes(attachment.data.size)}</p>
				</div>

				<div>
					<HiXMark onClick={removeAttachment} color="#b33939" size={16} />
				</div>
			</DocumentWrapper>}
		</AttachmentWrapper>
	</>