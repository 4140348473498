//* Post Creator Box Component
import { useState } from "react";
import { CommentWithAuthor, PostWithAuthor } from "../../utils/types";
import moment from "moment";
import { getPfp } from "../../utils/constants";

import { useNavigate } from "react-router";
import { formatText } from "../../utils/Markdown";
import { HiHeart } from "react-icons/hi2";
import { CommentBox, CommentContent, CommentReply, CommentUserWrapper, Pfp } from "../../styles/components/comments/Comment";
import { CommentUser } from "./root/Username";
import { CommentDropdown } from "./root/Dropdown";
import { Content } from "../../styles/components/posts/Post";
import { CommentFooter } from "./root/IconsBar";
import { EditingComment } from "./root/EditingComment";


type Props = {
	comment: CommentWithAuthor;
	setComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>; // This is a function that takes a new state and updates the state with it
	topComments: CommentWithAuthor[];
	setTopComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
	followingComments: CommentWithAuthor[];
	setFollowingComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
	comments: CommentWithAuthor[];
	post: PostWithAuthor;
	setReplyId: React.Dispatch<React.SetStateAction<string>>;
};

export const CommentDisplay = ({ comment, setComments, comments, post, setReplyId, topComments, setTopComments, followingComments, setFollowingComments }: Props) => {

	const navigate = useNavigate();

	const [editing, setEditing] = useState(false);


	if (editing) return <EditingComment setEditing={setEditing} comment={comment} setComments={setComments} comments={comments} post={post} setReplyId={setReplyId} topComments={topComments} setTopComments={setTopComments} followingComments={followingComments} setFollowingComments={setFollowingComments} />
	return (
		<CommentBox>
			<Pfp onClick={() => navigate(`/@${comment.author.username}`)} src={getPfp(comment.author.display_name, comment.author.pfp)} />

			<CommentContent>
				<CommentUserWrapper>
					<CommentUser comment={comment} op={comment.author.username === post.author.username} />

					<CommentDropdown comment={comment} comments={comments} followingComments={followingComments} post={post} setComments={setComments} setEditing={setEditing} setFollowingComments={setFollowingComments} setTopComments={setTopComments} topComments={topComments} />
				</CommentUserWrapper>

				<CommentReply>
					{comment.comment.replyToId && comment.repost && `Replying to @${comment.repost.author.username}`}
					{comment.comment.replyToId && comment.comment.likes.includes(post.author.userId) && <span style={{ margin: '0px 5px' }}>•</span>}
					{comment.comment.likes.includes(post.author.userId) && <HiHeart color="red" style={{ marginRight: '5px' }} />}
					{comment.comment.likes.includes(post.author.userId) && 'Liked by author'}
				</CommentReply>

				<Content>{formatText(comment.comment.content)}</Content>
				{comment.comment.edited !== 0 && <span style={{ fontSize: '11px', color: '#636363', marginBottom: '5px', display: 'block' }}>(last edited {moment(comment.comment.edited).fromNow()})</span>}

				{comment.comment.gif && <img src={comment.comment.gif} alt="gif" style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '5px' }} />}

				<CommentFooter comment={comment} comments={comments} setComments={setComments} setReplyId={setReplyId} />
			</CommentContent>
		</CommentBox>
	)
}