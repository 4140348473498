import { useNavigate } from "react-router";
import { CommentWithAuthor } from "../../../utils/types";
import { FlagsRenderer } from "../../root/FlagsRenderer";
import moment from "moment";
import { Displayname, NameWrapper, OPTag, Username } from "../../../styles/components/comments/Comment";
import tippy from "tippy.js";

type Props = {
	comment: CommentWithAuthor;
	op: boolean;
}

export const CommentUser = ({ comment, op }: Props) => {

	const navigate = useNavigate();

	tippy('[data-tippy-content]', { theme: "light-border" });
	return (
		<NameWrapper onClick={() => navigate(`/@${comment.author.username}`)}>
			<Displayname>
				{comment.author.display_name}
				<FlagsRenderer flags={comment.author.flags} />
				{op && <OPTag>OP</OPTag>}
			</Displayname>
			
			<Username>@{comment.author.username} • <span data-tippy-content={moment(comment.comment.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}>{moment(comment.comment.date).fromNow()}</span></Username>
		</NameWrapper>
	)
}