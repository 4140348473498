//* Post creator
import { useContext, useEffect, useState } from "react";
import { CharacterCount, CreatorBox, Icon, IconsBar, RepostWrapper, SmallPfp, TextBoxWrapper, TextInput } from "../../styles/components/posts/PostCreator";
import { getPfp, handleAttachmentChange, typeTexts } from "../../utils/constants";
import { Context } from "../../utils/Context";
import { HiOutlineCamera, HiOutlineDocument, HiOutlineFaceSmile, HiOutlineMapPin, HiOutlineVideoCamera, HiPaperAirplane } from "react-icons/hi2";
import { PostRepost } from "./root/Reposts";
import { PostWithAuthor } from "../../utils/types";
import { EmojiPickerWrapper } from "../../styles/General";
import Picker, { EmojiStyle, Theme } from 'emoji-picker-react';
import { PostCreatorAttachments } from "./root/AttachmentsPreview";
import { toast } from "react-toastify";
import { uploadDocument, uploadImage, uploadVideo } from "../../utils/cdn";
import { createPost } from "../../api/POST";


type PostCreatorProps = {
    repostId?: string;
    setRepostId?: (id: string) => void;
    tab: string;
}
export const PostCreator = ({ repostId, setRepostId, tab }: PostCreatorProps) => {

    const { user, loading, posts, followingPosts, topPosts, setPosts } = useContext(Context);

    const [content, setContent] = useState("");
    const [image, setImage] = useState({ preview: '', data: '' });
    const [video, setVideo] = useState({ preview: '', data: '' });
    const [attachment, setAttachment] = useState({ preview: '', data: '' });

    const [emojiPicker, setEmojiPicker] = useState(false);
    const [posting, setPosting] = useState(false);

    //* Create/send the post!
    const sendPost = async () => {
        if (loading || !user) return;
        if (posting) return;
        if (!user) {
            setPosting(false);
            return toast.error("You must be logged in to post!");
        }

        setPosting(true);
        if (content.replaceAll(" ", "").length === 0) {
            setPosting(false);
            return toast.error("Your post seems to be empty, please say something and try again!");
        }

        const imageUpload = await uploadImage(image);
        if (imageUpload === false) return setPosting(false);

        const videoUpload = await uploadVideo(video);
        if (videoUpload === false) return setPosting(false);

        const documentUpload = await uploadDocument(attachment);
        if (documentUpload === false) return setPosting(false);

        createPost(content, repostId ?? "", imageUpload, videoUpload, documentUpload)
            .then(({ data }) => {
                toast.success("Post created!");

                const postBox = document.getElementById("msg-box") as HTMLInputElement;
                postBox.value = "";
                setContent("");

                setPosts([...posts, {
                    post: data.post,
                    author: user,
                    repost: data.repost
                }].sort((a, b) => {
                    return b.post.date - a.post.date;
                }));
                setPosting(false);
                if (setRepostId) setRepostId("");

                setEmojiPicker(false);
                setImage({ data: '', preview: '' });
                setVideo({ data: '', preview: '' });
                setAttachment({ data: '', preview: '' });
            })
            .catch((err) => {
                setPosting(false);
                console.log(err);
                toast.error(err.response.data.error);
            });

        setPosting(false);
    }

    const creator = document.getElementById("creator") as HTMLDivElement;
    const [focused, setFocused] = useState(false);
    //* If the click that happens isn't on the creator, then un-focus the creator
    window.onclick = (e) => {
        if (creator && e.target !== creator && !creator.contains(e.target as Node)) setFocused(false);
        else setFocused(true);
    }


    //* Set the repost.
    const [repostPost, setRepostPost] = useState<PostWithAuthor | undefined>();
    useEffect(() => {
        if (repostId) setPlaceholder("Add a comment...");
        else setPlaceholder(typeTexts(user?.display_name ?? ""));

        if (repostId) setTimeout(() => setFocused(true), 10);
        if (repostId && posts.find(p => p.post.postId === repostId)) setRepostPost(posts.find(p => p.post.postId === repostId));
        if (repostId && followingPosts.find(p => p.post.postId === repostId)) setRepostPost(followingPosts.find(p => p.post.postId === repostId));
        if (repostId && topPosts.find(p => p.post.postId === repostId)) setRepostPost(topPosts.find(p => p.post.postId === repostId));
    }, [followingPosts, posts, repostId, topPosts, user]);


    //* Input Box Placeholder
    const [placeholder, setPlaceholder] = useState("What's on your mind?");
    useEffect(() => {
        if (repostId) setPlaceholder("Add a comment...");
        else setPlaceholder(typeTexts(user?.display_name ?? ""));
    }, [repostId, user?.display_name]);


    if (loading || !user) return <></>;
    return (
        <CreatorBox id="creator">
            <TextBoxWrapper>
                {!focused && <SmallPfp src={getPfp(user.display_name, user.pfp)} alt="" />}

                <TextInput disabled={posting} onChange={(e) => setContent(e.currentTarget.value)} maxLength={1000} id="msg-box" placeholder={placeholder} />

                {focused && <Icon disabled={posting}><HiPaperAirplane size={20} onClick={() => sendPost()} /></Icon>}
            </TextBoxWrapper>

            {repostId && repostPost && setRepostId && focused && <RepostWrapper onClick={() => setRepostId('')}>
                <PostRepost disableClick repost={repostPost} />
            </RepostWrapper>}

            {focused && <PostCreatorAttachments image={image.preview} video={video.preview} attachment={attachment} removeImage={() => setImage({ data: '', preview: '' })} removeAttachment={() => setAttachment({ data: '', preview: '' })} removeVideo={() => setVideo({ data: '', preview: '' })} />}

            {focused && <IconsBar>

                <div>
                    <Icon htmlFor="img-upload" disabled={!user.verified || posting}><HiOutlineCamera title="Image" size={20} /></Icon>
                    <Icon htmlFor="video-upload" disabled={!user.verified || posting}><HiOutlineVideoCamera title="Video" size={20} /></Icon>
                    <Icon htmlFor="document-upload" disabled={!user.verified || posting}><HiOutlineDocument title="Document/Other file" size={20} /></Icon>
                    <Icon disabled={posting}><HiOutlineFaceSmile onClick={() => setEmojiPicker(!emojiPicker)} title="Emoji Picker" size={20} /></Icon>
                    <Icon disabled><HiOutlineMapPin size={20} title="Location" /></Icon>
                </div>

                <CharacterCount>{content.length}/1000</CharacterCount>
            </IconsBar>}

            {emojiPicker && focused && <EmojiPickerWrapper>
                <Picker theme={Theme.AUTO} onEmojiClick={(e) => {
                    const textarea = document.getElementById("msg-box") as HTMLInputElement;
                    if (textarea) textarea.value = textarea.value + e.emoji;
                    setContent(content + e.emoji);
                }} emojiStyle={EmojiStyle.NATIVE} />
            </EmojiPickerWrapper>}

            <input id='img-upload' accept="image/*" style={{ display: 'none' }} type='file' name='file' onChange={(e) => handleAttachmentChange(e, user, setImage, setVideo, setAttachment)} />
            <input id='video-upload' accept="video/*" style={{ display: 'none' }} type='file' name='file' onChange={(e) => handleAttachmentChange(e, user, setVideo, setImage, setAttachment)} />
            <input id='document-upload' style={{ display: 'none' }} type='file' name='file' onChange={(e) => handleAttachmentChange(e, user, setAttachment, setVideo, setImage)} />
        </CreatorBox>
    )
}