import styled from "styled-components";

export const LoginContainer = styled.div`
	margin-top: 30px;
	padding: 40px;
	text-align: center;
	min-height: 460px;
	border-radius: 10px;
	background: var(--navbar-color);
	box-shadow: 0px 4px 4px 0px var(--box-shadow);

	@media (max-width: 600px) {
		padding: 20px;
	}
`;


export const NoteText = styled.p`
	color: var(--description-color);
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;	
	margin: 0px;
	margin-top: 18px;
	margin-bottom: 25px;

	span {
		color: var(--accent);
		cursor: pointer;
	}
`;

export const BoxContainer = styled.div`
	max-width: 300px;
	text-align: left;
	margin: 0px auto;
`;

export const InputText = styled.p`
	color: var(--text-color);
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	margin: 0px;
	line-height: normal;
	margin-top: 15px;
	margin-bottom: 5px;
`;

export const InputTextarea = styled.textarea`
	border-radius: 5px;
	border: 0.7px solid var(--navbar-border);
	background: var(--navbar-color);
	resize: none;
	width: calc(100% - 20px);
	color: var(--text-color);
	outline: 0px;
	font-family: Inter;
	padding: 8px 9px;
	font-size: 14px;
`;

export const Input = styled.input`
	border-radius: 5px;
	border: 0.7px solid var(--navbar-border);
	background: var(--navbar-color);
	color: var(--text-color);
	width: calc(100% - 20px);
	outline: 0px;
	font-family: Inter;
	padding: 8px 9px;
	font-size: 14px;
`;

export const InputSelect = styled.select`
	border-radius: 5px;
	border: 0.7px solid var(--navbar-border);
	background: var(--navbar-color);
	color: var(--text-color);
	width: calc(100% - 20px);
	outline: 0px;
	font-family: Inter;
	padding: 8px 9px;
	font-size: 14px;
`;

export const InputTooltip = styled.a`
	margin-bottom: 4px;
	margin-top: 4px;
	color: var(--description-color);
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const Button = styled.a<{disabled?: boolean}>`
	width: calc(100% - 20px);
	padding: 7px 10px;
	border-radius: 5px;
	background: var(--accent);
	color: white;	
	margin: 0px;
	transition: 0.2s;
	margin-top: 10px;
	display: block;
	text-align: center;

	:hover {
		color: #f5f5f5;
		background: var(--accent-hover);
		text-decoration: none;
	}

	${props => props.disabled && `
		pointer-events: none;
		background: var(--description-color);
	`}
`;

export const ButtonGoogle = styled.a<{disabled?: boolean}>`
	width: calc(100% - 20px);
	padding: 7px 10px;
	border-radius: 5px;
	background: #ffffff;
	color: #000000;
	display: flex;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.20);
	margin: 0px;
	transition: 0.2s;
	margin-top: 10px;
	display: block;
	text-align: center;

	:hover {
		color: #000000;
		text-decoration: none;
	}

	${props => props.disabled && `
		pointer-events: none;
		background: #d6d6d6;
	`}

	img {
		margin-right: 5px;
	}
`;