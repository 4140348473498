import styled from "styled-components";

export const PostBox = styled.div`
	background: var(--navbar-color);
	box-shadow: 0px 4px 4px 0px var(--box-shadow);
	padding: 15px 20px;
	border-radius: 8px;
	margin-bottom: 10px;

	@media (max-width: 250px) {
		display: block;
	}
`;

export const Pfp = styled.img`
	width: 45px;
	height: 45px;
	border-radius: 50%;
	object-fit: cover;
	object-fit: cover;
	cursor: pointer;
	margin-right: 15px;
	box-shadow: 0px 4px 4px 0px var(--box-shadow);

	@media (max-width: 500px) {
		margin-right: 7.5px;
	}
`;

export const NameWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

export const Userwrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;

export const Displayname = styled.p`
	color: var(--text-color);
	display: flex;
	cursor: pointer;
	align-items: center;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0px;
	margin-right: 10px;
	margin-bottom: 2px;
	width: fit-content;

	img {
		margin-left: 5px;
	}

	:hover {
		text-decoration: underline;
	}
`;

export const Username = styled.p`
	color: var(--description-color);
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0px;
	cursor: pointer;
`;

export const DotsImg = styled.img`
	cursor: pointer;
`;

export const Content = styled.p`
	margin: 0px;
	color: var(--content);
	font-family: Inter;
	white-space: pre-wrap;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0px 0px;
	margin-bottom: 5px;
`;

export const LastEdited = styled.span`
	font-size: 11px;
	color: var(--description-color);
`;

export const AttachmentWrapper = styled.div`
	max-width: 300px;
`;

export const Image = styled.img`
	max-width: 100%;
	max-height: 300px;
	border-radius: 8px;
	margin: 5px 0px;
	cursor: pointer;
`;

export const RemoveWrapper = styled.div`
	margin: 5px 0px;
	margin-left: 5px;
	cursor: pointer;
`;

export const Video = styled.video`
	max-width: 100%;
	border-radius: 8px;
	margin: 5px 0px;
`;

export const DocumentWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
		width: 100%;
    justify-content: space-between;
    border: 1px solid var(--navbar-border);
    border-radius: 5px;
    padding: 13px 15px;

    b {
        font-size: 14px;
        margin: 0px;
        font-weight: 600;
        line-height: normal;
        color: var(--text-color);
				cursor: pointer;

				:hover {
					text-decoration: underline !important;
				}
    }

    p {
        font-size: 13px;
        margin: 0px;
        font-weight: 400;
        line-height: normal;
        color: var(--description-color)
    }

    svg {
        cursor: pointer;
    }
`;

export const RepostBox = styled.div`
  border: 1px solid var(--navbar-border);
  padding: 10px;
  border-radius: 8px;
	cursor: pointer;
  margin-top: 5px;
	margin-bottom: 10px;
`;

export const RepostUser = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  img {
      border-radius: 50%;
	object-fit: cover;
			object-fit: cover;
      width: 30px;
      height: 30px;
      margin-right: 15px;
  }

  p {
      margin: 0px;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      color: var(--text-color);

      span {
          color: var(--description-color);
          font-weight: 400;
      }
  }
`;

export const RepostContent = styled.p`
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: var(--content);
		white-space: pre-wrap;
`;

export const RepostComments = styled.p`
	margin: 0px;
	color: var(--description-color);
	font-size: 13px;
	margin-top: 5px;
`;

export const IconsBar = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0px;
	padding-bottom: 0px;
	margin-top: 0px;
`;

export const ActionButton = styled.div<{ saving?: boolean }>`
	margin: 0px;
	display: flex;
	align-items: center;
	margin-right: 15px;
	color: var(--action-button);
	font-family: Inter;
	cursor: pointer;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	transition: 0.1s;

	@media (max-width: 500px) {
		margin-right: 15px;
	}

	svg {
		margin-right: 5px;
	}

	@media (max-width: 450px) {
		span {
			display: none;
		}
}

img {
	margin-right: 5px;
	cursor: pointer;
}

span {
	margin-left: 5px;
}

${(props) => props.saving && `
	img {
		cursor: default;
	}
`}

:hover {
	color: var(--content);
}

:active {
	transform: translateY(1px);
}

//* Disable when saving
${(props) => props.saving && `
	cursor: default;

	:active {
		transform: translateY(0px) !important;
	}
`}
`;


export const EditingTextarea = styled.textarea`
	width: calc(100% - 20px);
	border: none;
	resize: none;
	outline: none;
	background: var(--box-shadow);
	color: var(--text-color);
	border-radius: 8px;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	padding: 10px;
	line-height: normal;
	white-space: pre-wrap;
`;

export const EditingButton = styled.button`
	background: var(--search-bg);
	color: var(--text-color);
	border: none;
	border-radius: 8px;
	padding: 10px 15px;
	margin-right: 10px;
	cursor: pointer;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 10px;
`;

export const RepostCloseFlex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const RepostClose = styled.div`
	cursor: pointer;
	margin-right: 5px;
`;