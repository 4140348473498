import axios from "axios";
import { API_URL, RequestHeader } from "../utils/constants";

export const createComment = async (postId: string, content: string, gif: string, replyToId: string) =>
    await axios.post(`${API_URL}/comment/create`, { content, postId, gif, replyToId }, RequestHeader);

export const getComments = async (postId: string, tab: string) =>
    await axios.post(`${API_URL}/comment/get`, { postId, tab }, RequestHeader);

export const likeComment = async (postId: string, commentId: string) =>
    await axios.post(`${API_URL}/comment/like`, { postId, commentId }, RequestHeader);

export const getLikesComment = async (postId: string, commentId: string, previousAmount: number) =>
    await axios.post(`${API_URL}/comment/likes`, { postId, commentId, previousAmount }, RequestHeader);

export const getMoreComments = async (postId: string, commentCount: number, tab: string) =>
    await axios.post(`${API_URL}/comment/get-more`, { commentCount, postId, tab }, RequestHeader);

export const deleteComment = async (postId: string, commentId: string) =>
    await axios.post(`${API_URL}/comment/delete`, { postId, commentId }, RequestHeader);

export const editComment = async (postId: string, commentId: string, content: string) =>
    await axios.post(`${API_URL}/comment/edit`, { postId, commentId, content }, RequestHeader);