import { useEffect, useState, useRef } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from 'react-router';
import 'tippy.js/dist/tippy.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-loading-skeleton/dist/skeleton.css'

import { Navbar } from './components/root/Navbar';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Context } from './utils/Context';
import { OtherUser, PostWithAuthor, User } from './utils/types';
import { authUser } from './api/AUTH';
import { NotLoggedInBanner } from './components/root/NoLogBanner';
import { Home } from './pages/Home';
import { getRecommendedUsers, getUserNotifications } from './api/USER';
import { getPosts, getRecommendedPosts, getTrendingPosts } from './api/POST';
import { PostPage } from './pages/Post';
import { ProfilePage } from './pages/Profile';
import { SearchPage } from './pages/Search';
import { SkeletonTheme } from 'react-loading-skeleton';
import { WS_URL } from './utils/constants';
import styled from 'styled-components';
import { Settings } from './pages/Settings';
import { VerifyEmail } from './pages/settings/VerifyEmail';
import { ChangeEmail } from './pages/settings/ChangeEmail';
import { NotFound } from './pages/NotFound';
import { ChangePassword } from './pages/settings/ChangePassword';
import { DeleteAccount } from './pages/settings/DeleteAccount';
import { ForgotPassword } from './pages/settings/ForgotPassword';
import ScrollButton from './components/root/ScrollToTop';
import { NotificationsPage } from './pages/Notifications';
import { BookmarksPage } from './pages/Bookmarks';
import { Flex } from './styles/pages/Profile';
import { StaffPage } from './pages/Staff';

export const Link = styled.span`
	a {
		color: #2a3946;
		margin: 0px;
		text-decoration: none;
	}
`;

export const Close = styled.button`
	border: none;
	background: none;
	border-radius: 100px;
	padding: 5px;
	display: grid;
	place-items: center;
	cursor: pointer;
	
`;

function App() {

  const [user, setUser] = useState<User | null>(null);
  const [recommendedUsers, setRecommendedUsers] = useState<OtherUser[]>([]);
  const [recommendedPosts, setRecommendedPosts] = useState<PostWithAuthor[]>([]);
  const [trendingPosts, setTrendingPosts] = useState<PostWithAuthor[]>([]);
  const [loading, setLoading] = useState(true);

  const [posts, setPosts] = useState<PostWithAuthor[]>([]);
  const [loadingPosts, setLoadingPosts] = useState(true);

  const [bookmarks, setBookmarks] = useState<string[]>([]);

  const [topPosts, setTopPosts] = useState<PostWithAuthor[]>([]);
  const [loadingTopPosts, setLoadingTopPosts] = useState(false);

  const [followingPosts, setFollowingPosts] = useState<PostWithAuthor[]>([]);
  const [loadingFollowingPosts, setLoadingFollowingPosts] = useState(false);

  const fetched = useRef(false);
  useEffect(() => {
    if (fetched.current) return;
    fetched.current = true;

    getRecommendedUsers()
      .then(({ data }) => {
        setRecommendedUsers(data);
      }).catch((err) => {
        console.log(err);
      });

    getRecommendedPosts()
      .then(({ data }) => {
        setRecommendedPosts(data);
      }).catch((err) => {
        console.log(err);
      });

    getTrendingPosts()
      .then(({ data }) => {
        setTrendingPosts(data);
      }).catch((err) => {
        console.log(err);
      });

    authUser()
      .then(({ data }) => {
        setUser(data.user);
        setBookmarks(data.user.bookmarks);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
      });

    getPosts("latest")
      .then(({ data }) => {
        setPosts(data);
        setLoadingPosts(false);
      }).catch((err) => {
        console.log("There was an error loading the posts!" + err);
        toast.error("There was an error loading the posts!" + err);

        setLoadingPosts(false);
      });
  }, []);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [loadedAllNotifications, setLoadedAllNotifications] = useState(false);
  useEffect(() => {
    getUserNotifications()
      .then(({ data }) => {
        setNotifications(data);
        setLoadingNotifications(false);
      }).catch((err) => {
        console.log("There was an error loading the notifications! " + err);

        setLoadingNotifications(false);
      });
  }, []);

  //* When ANY textarea in  the document is changed, trigger the event
  useEffect(() => {
    document.addEventListener("input", (e) => {
      if ((e.target as HTMLElement).tagName === "TEXTAREA") {
        const textarea = e.target as HTMLTextAreaElement;
        textarea.style.height = "0";
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    });
  }, []);

  return (
    <>
      {/* {!connecting && !connected && <StatusBox>
        <Content>
          <div></div>
          <p className="fd">Not connected to the server, live updates disabled. • <Link><a href="#" onClick={() => window.location.reload()}>Try to reconnect →</a></Link></p>
          <div></div>
        </Content>
      </StatusBox >} */}

      <SkeletonTheme baseColor="var(--skeleton-base)" highlightColor="var(--skeleton-shine)">
        <Context.Provider value={{
          notifications,
          setNotifications,
          loadingNotifications,
          loadedAllNotifications,
          setLoadedAllNotifications,
          setLoadingNotifications,
          user,
          setUser,
          loading,
          recommendedUsers,
          posts,
          loadingPosts,
          setPosts,
          trendingPosts,
          recommendedPosts,
          topPosts,
          setTopPosts,
          loadingTopPosts,
          followingPosts,
          setFollowingPosts,
          loadingFollowingPosts,
          setLoadingFollowingPosts,
          setLoadingTopPosts,
          bookmarks,
          setBookmarks
        }}>

          {!user && !loading && <NotLoggedInBanner />}

          <ToastContainer
            autoClose={5000}
            newestOnTop
            closeOnClick
            pauseOnHover
            theme="light"
            draggable={false}
          />
          <ScrollButton />

          <Routes>
            <Route path='*'
              element={<Navbar />}
            />
          </Routes>

          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/post/:postId' element={<PostPage />} />
            <Route path='/@:username' element={<ProfilePage />} />
            <Route path='/search' element={<SearchPage />} />
            <Route path='/notifications' element={<NotificationsPage />} />
            <Route path='/bookmarks' element={<BookmarksPage />} />


            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />

            <Route path='/settings' element={<Settings />} />
            <Route path='/settings/verify-email' element={<VerifyEmail />} />
            <Route path='/settings/change-email' element={<ChangeEmail />} />
            <Route path='/settings/change-password' element={<ChangePassword />} />
            <Route path='/settings/delete-account' element={<DeleteAccount />} />
            <Route path='/account/reset-password' element={<ForgotPassword />} />

            <Route path='/admin' element={<StaffPage />} />

            <Route path='*' element={<NotFound />} />
          </Routes>

        </Context.Provider>
      </SkeletonTheme>
    </>
  );
}

export default App;
