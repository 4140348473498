import { useContext } from "react";
import { Context } from "../../utils/Context";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { getPfp } from "../../utils/constants";
import { FlagsRenderer } from "../root/FlagsRenderer";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const Container = styled.div`
    max-width: 325px;
    width: 100%;

    @media (max-width: 1350px) {
        display: none;
    }
`;

const TabsBox = styled.div`
    background-color: var(--navbar-color);
    border-radius: 8px;
    padding: 17px 0px 9px 0px;
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
`;

const BoxTitle = styled.p`
    text-transform: uppercase;
    border-bottom: 1px solid var(--navbar-border);
    padding: 0px 22px;
    padding-bottom: 15px;
    color: var(--text-color);
    font-size: 16px;
    font-style: normal;
    margin: 0px;
    margin-bottom: 7.5px;
    font-weight: 600;
    line-height: normal;
`;

const User = styled.div`
    display: flex;
    align-items: center;
    padding: 7.5px 22px;
    cursor: pointer;
    transition: 0.2s;

    :hover {
        background-color: var(--hover-color);
    }
`;

const Pfp = styled.img`
    border-radius: 50%;
    object-fit: cover;
    width: 35px;
    height: 35px;
    margin-right: 15px;
`;

const Username = styled.p`
    margin: 0px;
    color: var(--description-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Displayname = styled.p`
    color: var(--text-color);
    margin: 0px;
    font-family: Inter;
    word-break: break-all;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;

    img {
        margin-left: 5px;
    }
`;


export const RecommendedBar = () => {

    const navigate = useNavigate();

    const { recommendedUsers, recommendedPosts, trendingPosts } = useContext(Context);

    return (
        <div style={{ maxWidth: '325px', height: 'calc(100vh - 117px)', position: 'sticky', top: '117px', overflowY: 'scroll', borderRadius: '8px', marginTop: '35px' }}>
            <Container>
                <TabsBox style={{ marginBottom: '20px' }}>
                    <BoxTitle>Recommended Users</BoxTitle>
                    {recommendedUsers.map((user) => <User onClick={() => navigate(`/@${user.username}`)}>
                        <Pfp src={getPfp(user.display_name, user.pfp)} alt="" />

                        <div>
                            <Displayname>{user.display_name} <FlagsRenderer flags={user.flags} /></Displayname>
                            <Username>@{user.username}</Username>
                        </div>
                    </User>)}

                    {!recommendedUsers.length && [...Array(6)].map(() => <User>
                        <Skeleton style={{ marginRight: '15px' }} width={35} height={35} borderRadius={50} />

                        <div>
                            <Displayname><Skeleton width={60} /></Displayname>
                            <Username><Skeleton width={100} /></Username>
                        </div>
                    </User>)}
                </TabsBox>

                <TabsBox style={{ marginBottom: '20px' }}>
                    <BoxTitle>Top Posts Last week</BoxTitle>
                    {trendingPosts.sort((a, b) => b.post.likes.length - a.post.likes.length).map((post) => <User onClick={() => navigate(`/post/${post.post.postId}`)}>
                        <div>
                            <Displayname>{post.post.content.slice(0, 80)}{post.post.content.length > 80 && '...'}</Displayname>
                            <Username>@{post.author.username} • {post.post.likes.length} like(s) • {moment(post.post.date).fromNow()}</Username>
                        </div>
                    </User>)}

                    {!trendingPosts.length && [...Array(5)].map(() => <User>
                        <div>
                            <Displayname><Skeleton width={150} /></Displayname>
                            <Username><Skeleton width={100} /></Username>
                        </div>
                    </User>)}
                </TabsBox>

                <TabsBox style={{ marginBottom: '25px' }}>
                    <BoxTitle>Recommended Posts</BoxTitle>
                    {recommendedPosts.map((post) => <User onClick={() => navigate(`/post/${post.post.postId}`)}>
                        <div>
                            <Displayname>{post.post.content.slice(0, 100)}</Displayname>
                            <Username>@{post.author.username} • {moment(post.post.date).fromNow()}</Username>
                        </div>
                    </User>)}

                    {!recommendedUsers.length && [...Array(5)].map(() => <User>
                        <div>
                            <Displayname><Skeleton width={150} /></Displayname>
                            <Username><Skeleton width={100} /></Username>
                        </div>
                    </User>)}
                </TabsBox>
            </Container>
        </div>
    )
}