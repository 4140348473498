import styled from "styled-components";
import Skeleton from 'react-loading-skeleton';
import TripleDots from "../../assets/icons/tripple-dots.svg";

//* Styles for the Post Renderer
const PostBox = styled.div`
	background: var(--navbar-color);
	box-shadow: 0px 4px 4px 0px var(--box-shadow);
	padding: 20px 25px;
	border-radius: 8px;
	margin-bottom: 10px;
	display: flex;

	@media (max-width: 250px) {
		display: block;
	}
`;

const Pfp = styled.div`
	border-radius: 50%;
	object-fit: cover;
	margin-right: 15px;

	@media (max-width: 500px) {
		margin-right: 7.5px;
	}
`;

const UsernameBox = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`;

const NameWrapper = styled.div`
	display: flex;
	cursor: pointer;
	align-items: center;

	@media (max-width: 500px) {
		display: block;
		margin-bottom: 5px;
	}
`;

const DotsImg = styled.img`
	cursor: pointer;
`;

export const PostLoadingSkeleton = () => (
	<>
		<PostBox>
			<Pfp>
				<Skeleton height={40} width={40} borderRadius={50} />
			</Pfp>

			<div style={{ width: '100%' }}>
				<UsernameBox>
					<NameWrapper>
						<Skeleton width={(Math.random() * 50) + 70} height={20} style={{ marginRight: '10px' }} />
						<Skeleton width={(Math.random() * 50) + 40} height={20} />
					</NameWrapper>

					<DotsImg src={TripleDots} alt="Triple Dots" />
				</UsernameBox>

				<Skeleton count={Math.random() * 4} />
			</div>
		</PostBox>
	</>
)