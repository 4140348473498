import { FullLogo, MobileIcon, MobileLine, MobileMenuWrapper, NavbarContainer, NavbarFlexContainer, NavbarLink, NavbarSearchFlex, Search, SearchBox, SmallLogo } from "../../styles/components/root/Navbar";
import Logo from "../../assets/logo_lightmode.png";
import LogoDark from "../../assets/logo_darkmode.png";
import LogoSmall from "../../assets/logo_small.png";
import { useNavigate } from "react-router";
import { HiSearch } from "react-icons/hi";
import { useContext, useState } from "react";
import { Context } from "../../utils/Context";
import { HiOutlineMoon, HiOutlineSun } from "react-icons/hi2";

export const Navbar = () => {

	const navigate = useNavigate();

	const { user } = useContext(Context);

	//* Mobile menu animations
	const [mobile, setMobile] = useState(false);

	const [theme, setTheme] = useState(localStorage.getItem('theme') === 'dark' ? 'dark' : 'light');

	return (
		<>
			<NavbarContainer>
				<NavbarFlexContainer>
					<a href="#" onClick={() => navigate('/')}>
						<FullLogo src={theme === 'dark' ? LogoDark : Logo} alt="logo" />
						<SmallLogo src={LogoSmall} alt="logo" />
					</a>

					<NavbarSearchFlex>
						{/* <span title="Direct Messages" onClick={() => navigate('/direct-messages')}>
							<HiChatBubbleBottomCenter height={20} width={20} />
						</span> */}
						<span title="Dark/Light Mode" onClick={() => {
						if (document.body.className === "dark-mode") {
							document.body.className = "light-mode";
							setTheme('light');
							localStorage.setItem('theme', 'light');
						} else {
							document.body.className = "dark-mode";
							localStorage.setItem('theme', 'dark');
							setTheme('dark');
						}
					}}>
							{theme === 'dark'&& <HiOutlineMoon size={20} />}
							{theme === 'light' && <HiOutlineSun size={20} />}
						</span>

						<Search onClick={() => navigate('/search')}>
							<HiSearch height={20} width={20} />

							<SearchBox>Search...</SearchBox>
						</Search>
					</NavbarSearchFlex>

					<MobileIcon onClick={() => setMobile(!mobile)}>
						<MobileLine open={mobile} index={0} />
						<MobileLine open={mobile} index={1} />
						<MobileLine open={mobile} index={2} />
					</MobileIcon>
				</NavbarFlexContainer>


				{/* Menu for mobile, that opening on hamburger button tap */}
				{mobile && <MobileMenuWrapper className="fd">
					<NavbarLink onClick={() => navigate('/')}>Home</NavbarLink>
					{user && <>
						<NavbarLink onClick={() => navigate(`/@${user.username}`)}>Profile</NavbarLink>
						<NavbarLink onClick={() => navigate('/notifications')}>Notifications</NavbarLink>
						<NavbarLink onClick={() => navigate('/bookmarks')}>Saved Posts</NavbarLink>
						<NavbarLink onClick={() => navigate('/settings')}>Settings</NavbarLink>
						<NavbarLink onClick={() => {
							document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
							setTimeout(() => {
								window.location.reload();
							}, 1000);
						}} style={{ color: '#2d1313' }}>Logout</NavbarLink>
					</>}
					{!user && <>
						<NavbarLink onClick={() => navigate('/login')}>Login</NavbarLink>
						<NavbarLink onClick={() => navigate('/register')}>Register</NavbarLink>
					</>}

					<Search style={{ display: 'flex', marginTop: '20px' }} onClick={() => navigate('/search')}>
						<HiSearch height={20} width={20} />

						<SearchBox>Search...</SearchBox>
					</Search>
				</MobileMenuWrapper>}
			</NavbarContainer>
		</>
	)
}