import { useState } from "react";
import { Post } from "../../../utils/types";
import { CDN_URL } from "../../../utils/constants";
import { AttachmentWrapper, DocumentWrapper, Image, Video } from "../../../styles/components/posts/Post";
import { HiOutlineDownload } from "react-icons/hi";
import { formatBytes } from "../../../utils/Markdown";
import { AttachmentPoup } from "../../root/AttachmentPopup";

type Props = {
	post: Post;
}
export const PostAttachments = ({ post }: Props) => {

	const [popup, setPopup] = useState(false);

	return (
		<>
			<AttachmentWrapper>
				{post.image && <Image onClick={() => setPopup(true)} src={`${CDN_URL}/${post.image}`} />}

				{post.video && <Video src={`${CDN_URL}/${post.video}`} controls />}

				{post.attachment && <DocumentWrapper onClick={() => window.location.href = `${CDN_URL + '/download/' + post.attachment?.url}`}>
					<div>
						<b>{post.attachment.name}</b>
						<p>{formatBytes(post.attachment.size)}</p>
					</div>

					<div>
						<HiOutlineDownload color="#747474" size={16} />
					</div>
				</DocumentWrapper>}
			</AttachmentWrapper>

			{popup && <AttachmentPoup setShown={() => setPopup(false)} image={post.image} />}
		</>
	)
}