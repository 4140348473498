import { useNavigate } from "react-router";
import { PageContainer, PageTitle } from "../styles/General";
import { BoxContainer, Button, ButtonGoogle, Input, InputText, LoginContainer, NoteText } from "../styles/pages/Login";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { API_URL, hashPassword, testEmail, testPassword } from "../utils/constants";
import { registerCheck, registerUser } from "../api/AUTH";
import { Context } from "../utils/Context";
import { HiOutlineEye } from "react-icons/hi2";	

export const Register = () => {

	const navigate = useNavigate();

	const { setUser, user } = useContext(Context);

	const [username, setUsername] = useState('');
	const [displayname, setDisplayname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const register = () => {
		if (loading) return;

		if (!username) return toast.error('Please enter a username');
		if (!displayname) return toast.error('Please enter a displayname of at least 1 character');
		if (!email) return toast.error('Please enter a email');
		if (!password) return toast.error('Please enter a password');


		if (username.length > 20 || username.length < 3) return toast.error("Username must be between 3 and 20 characters");
		if (!/^[A-Za-z0-9_-]*$/g.test(username)) return toast.error("Username can only contain letters, numbers, underscores and dashes");

		if (displayname.length > 20 || displayname.length < 1) return toast.error("Displayname must be between 1 and 20 characters");

		if (!testEmail(email)) return toast.error("Please enter a valid email address");
		if (!testPassword(password)) return toast.error("Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter and one special character");

		setLoading(true);
		registerCheck(username, email).then(async () => {

			registerUser(displayname, username, await hashPassword(password), email)
				.then(({ data }) => {
					toast.success('Account created!');
					document.cookie = `token=${data.token}; expires=` + new Date(86400000 + Date.now()).toUTCString() + '; path=/;';

					setTimeout(() => {
						setUser(data.user);
						window.location.href = `/@${data.user.username}`;
					}, 500);

					setLoading(false);
				}).catch((err) => {
					setLoading(false);
					return toast.error(err.response.data);
				});

		}).catch((err) => {
			setLoading(false);
			return toast.error(err.response.data);
		});
	}

	document.onkeydown = (e) => {
		if (e.key === 'Enter') register();
	}

	// Password visibility
    function togglePw() {
        var passwordBox: any = document.getElementById("password");
        if (passwordBox.type === "password") { passwordBox.type = "text"; } else { passwordBox.type = "password"; }
    }

	if (user) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>Register</PageTitle>
				<NoteText>Already have an account? <span onClick={() => navigate('/login')}>Login today!</span></NoteText>

				<BoxContainer>
					<InputText>Username</InputText>
					<Input onInput={(e) => setUsername(e.currentTarget.value)} id="username" placeholder="Username" />

					<InputText>Displayname</InputText>
					<Input onInput={(e) => setDisplayname(e.currentTarget.value)} id="displayname" placeholder="Displayname" />

					<InputText>Email</InputText>
					<Input onInput={(e) => setEmail(e.currentTarget.value)} id="email" placeholder="Email" />

					<InputText>Password</InputText>
					<div>
						<Input onInput={(e) => setPassword(e.currentTarget.value)} id="password" placeholder="Password" type="password" />
						<HiOutlineEye onClick={togglePw} style={{ marginLeft: '-25px', marginTop: '9px', cursor: 'pointer', position: 'absolute' }} />
					</div>

					<Button disabled={loading} onClick={register} style={{ marginTop: '22px' }} href="#">Register</Button>
					<ButtonGoogle onClick={() => window.location.href = `${API_URL}/auth/google`} disabled={loading}><img height={13} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK5q0FP74VV9wbfwP378_7kj7iDomHuKrxkXsxDdUT28V9dlVMNUe-EMzaLwaFhneeuZI&usqp=CAU" /> Register with Google</ButtonGoogle>
				</BoxContainer>
			</LoginContainer>
		</PageContainer>
	);
}