import { useNavigate } from "react-router";
import { PageContainer, PageTitle } from "../styles/General";
import { BoxContainer, Button, ButtonGoogle, Input, InputText, InputTooltip, LoginContainer, NoteText } from "../styles/pages/Login";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { loginUser } from "../api/AUTH";
import { Context } from "../utils/Context";
import { HiOutlineEye } from "react-icons/hi2";
import { API_URL, loginErrors } from "../utils/constants";

export const Login = () => {

	const navigate = useNavigate();

	const { setUser, user } = useContext(Context);

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const [searchParams] = useState(new URLSearchParams(window.location.search));
	useEffect(() => {
		if (searchParams.get('error')) {
			toast.error(loginErrors.find((e) => e.error === searchParams.get('error'))?.message || 'An error occurred');
		}

		if (searchParams.get('success')) {
			if (searchParams.get("token")) {
				document.cookie = `token=${searchParams.get("token")}; expires=` + new Date(864000000 + Date.now()).toUTCString() + '; path=/;';
				toast.success("Successfully logged in!");
				setTimeout(() => {
					window.location.href = "/";
				}, 1000);
			}
		}
	}, [searchParams]);

	const login = () => {
		if (loading) return;

		if (!username) return toast.error('Please enter a username');
		if (!password) return toast.error('Please enter a password');

		setLoading(true);

		loginUser(username, password)
			.then(({ data }) => {

				toast.success(`Logged in as @${data.user.username}!`);
				document.cookie = `token=${data.token}; expires=` + new Date(864000000 + Date.now()).toUTCString() + '; path=/;';

				setTimeout(() => {
					setUser(data.user);
					window.location.href = `/`;
				}, 1000);

				setLoading(false);
			}).catch((err) => {
				setLoading(false);
				return toast.error(err.response.data);
			});
	}

	document.onkeydown = (e) => {
		if (e.key === 'Enter') login();
	}

	// Password visibility
	function togglePw() {
		var passwordBox: any = document.getElementById("password");
		if (passwordBox.type === "password") { passwordBox.type = "text"; } else { passwordBox.type = "password"; }
	}

	if (user) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>Login</PageTitle>
				<NoteText>Don't have an account? <span onClick={() => navigate('/register')}>Register today!</span></NoteText>

				<BoxContainer>
					<InputText>Username or Email</InputText>
					<Input id="username" onChange={(e) => setUsername(e.currentTarget.value)} placeholder="Username or Email" />


					<InputText>Password</InputText>
					<div>
						<Input onChange={(e) => setPassword(e.currentTarget.value)} placeholder="Password" type="password" id="password" />
						<HiOutlineEye onClick={togglePw} style={{ marginLeft: '-25px', marginTop: '9px', cursor: 'pointer', position: 'absolute' }} />
					</div>
					<InputTooltip href="#" onClick={() => navigate('/account/reset-password')}>Forgot Password?</InputTooltip>

					<Button disabled={loading} onClick={login} href="#">Login</Button>
					<ButtonGoogle onClick={() => window.location.href = `${API_URL}/auth/google`} disabled={loading}><img height={13} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK5q0FP74VV9wbfwP378_7kj7iDomHuKrxkXsxDdUT28V9dlVMNUe-EMzaLwaFhneeuZI&usqp=CAU" /> Login with Google</ButtonGoogle>
				</BoxContainer>
			</LoginContainer>
		</PageContainer>
	);
}