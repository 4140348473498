import { useNavigate } from "react-router";
import { Listbox, MenuButton, MenuItem } from "../../../styles/General";
import { SITE_URL, copy } from "../../../utils/constants";
import { PostWithAuthor } from "../../../utils/types";
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { useContext, useState } from "react";
import { Context } from "../../../utils/Context";
import { HiOutlineArrowPath, HiOutlineClipboard, HiOutlinePencil, HiOutlineTrash, HiOutlineUserCircle } from "react-icons/hi2";
import { deletePost } from "../../../api/POST";
import { toast } from "react-toastify";
import { HiDotsHorizontal } from "react-icons/hi";

type Props = {
	post: PostWithAuthor;
	setRepostId?: (id: string) => void;
	disableActions?: boolean;
	setEditing: (editing: boolean) => void;
}
export const PostDropdown = ({ post, setRepostId, disableActions, setEditing }: Props) => {

	const navigate = useNavigate();
	const { user, setTopPosts, setPosts, setFollowingPosts, followingPosts, topPosts, posts } = useContext(Context);

	const [deleting, setDeleting] = useState(false);
	const deletePostFunction = () => {
		if (deleting) return;
		if (!user) return toast.error("You are not logged in.");

		setDeleting(true);

		toast.promise(
			deletePost(post.post.postId)
				.then(() => {
					setPosts(posts.filter((p) => p.post.postId !== post.post.postId));
					setFollowingPosts(followingPosts.filter((p) => p.post.postId !== post.post.postId));
					setTopPosts(topPosts.filter((p) => p.post.postId !== post.post.postId));
					setDeleting(false);

					if (window.location.pathname === `/post/${post.post.postId}`) navigate("/");
				}), {
			pending: 'Deleting...',
			success: 'Post Deleted!',
			error: {
				render() {
					setDeleting(false);
					return 'Failed to delete post.'
				},
			}
		})
	}

	const repostPost = (postId: string) => {
		if (!user) return toast.error("You are not logged in.");
		if (setRepostId) setRepostId(postId);

		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	return (
		<Dropdown>
			<MenuButton><HiDotsHorizontal color="var(--text-color)" /></MenuButton>

			<Menu slots={{ listbox: Listbox }}>
				<MenuItem onClick={() => navigate(`/@${post.author.username}`)}><HiOutlineUserCircle /> Visit Profile</MenuItem>
				<MenuItem onClick={() => copy(`${SITE_URL}/post/${post.post.postId}`)}><HiOutlineClipboard /> Copy Link</MenuItem>

				{user && !disableActions && window.location.pathname === "/" && <MenuItem onClick={() => repostPost(post.post.postId)}><HiOutlineArrowPath /> Repost</MenuItem>}
				{user?.userId === post.author.userId && !disableActions && <MenuItem onClick={() => setEditing(true)}><HiOutlinePencil /> Edit</MenuItem>}
				{(user?.userId === post.author.userId || user?.flags.includes("moderator")) && !disableActions &&
					<MenuItem onClick={() => deletePostFunction()} style={{ color: '#d11b1b' }}><HiOutlineTrash /> Delete</MenuItem>}
			</Menu>
		</Dropdown>
	)
}