//* Post Creator Box Component
import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/Context";
import { toast } from "react-toastify";
import { getPfp } from "../../utils/constants";
import { CommentWithAuthor } from "../../utils/types";
import { createComment } from "../../api/COMMENT";
import { HiOutlineFaceSmile, HiOutlineGif, HiPaperAirplane } from "react-icons/hi2";
import Picker, { EmojiStyle, Theme } from 'emoji-picker-react';
import GifPicker from 'gif-picker-react';
import { formatText } from "../../utils/Markdown";
import { Box } from "../../styles/components/comments/CommentCreator";
import { CharacterCount, Icon, IconsBar, SmallPfp, TextBoxWrapper, TextInput } from "../../styles/components/posts/PostCreator";
import { EmojiPickerWrapper } from "../../styles/General";
import { RepostBox, RepostContent, RepostUser } from "../../styles/components/posts/Post";

type Props = {
    comments: CommentWithAuthor[];
    setComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
    setTopComments: React.Dispatch<React.SetStateAction<CommentWithAuthor[]>>;
    replyId: string;
    setReplyId: React.Dispatch<React.SetStateAction<string>>;
    postId: string | undefined;
};

export const CommentCreator = ({ comments, setComments, replyId, setReplyId, setTopComments, postId }: Props) => {

    const { user, loading } = useContext(Context);

    const [content, setContent] = useState("");
    const [gif, setGif] = useState("");

    const [emojiPicker, setEmojiPicker] = useState(false);
    const [gifPicker, setGifPicker] = useState(false);


    const [posting, setPosting] = useState(false);
    const post = () => {
        if (loading || !user) return;
        if (posting) return;
        if (!user) {
            setPosting(false);
            return toast.error("You must be logged in to post!");
        }

        if (content.length === 0) {
            setPosting(false);
            return toast.error("Content must not be empty!");
        }

        const commentBox = document.getElementById("msg-box") as HTMLInputElement;
        if (!commentBox) return;

        createComment(postId ?? "", content, gif, replyId)
            .then(({ data }) => {
                toast.success("Comment created!");
                commentBox.value = "";
                setContent("");
                setGif("");
                setGifPicker(false);
                setEmojiPicker(false);
                setReplyId("");

                setComments((c) => [...c, {
                    comment: data.comment,
                    author: data.author,
                    repost: data.repost
                }].sort((a, b) => b.comment.date - a.comment.date));

                setTopComments((tC) => [...tC, {
                    comment: data.comment,
                    author: data.author,
                    repost: data.repost
                }].sort((a, b) => b.comment.likes.length - a.comment.likes.length));

                setPosting(false);
            })
            .catch((err) => {
                setPosting(false);
                console.log(err);
                toast.error(err.response.data.error);
            });
    }


    const creator = document.getElementById("creator") as HTMLInputElement;
    const [focused, setFocused] = useState(false);
    //* If the click that happens isnt on the creator, then unfocus the creator
    window.onclick = (e) => {
        if (creator && e.target !== creator && !creator.contains(e.target as Node) && !emojiPicker && !gifPicker) setFocused(false)
        else setFocused(true);
    }

    const [replyComment, setReplyComment] = useState<CommentWithAuthor | null>(null);
    useEffect(() => {
        if (replyId) {
            const comment = comments.find(comment => comment.comment.commentId === replyId);
            if (comment) setReplyComment(comment);
        }
    }, [replyId, comments]);


    if (loading && !user) return <Box />;
    if (!user) return <Box>You are not logged in! In order to create a comment, please login.</Box>;
    return (
        <Box id="creator">
            <TextBoxWrapper>
                {!focused && <SmallPfp src={getPfp(user.display_name, user.pfp)} alt="" />}

                <TextInput disabled={posting} onChange={(e) => setContent(e.currentTarget.value)} maxLength={1000} id="msg-box" placeholder={`Leave a comment...`} />

                {focused && <Icon disabled={posting}><HiPaperAirplane size={20} onClick={() => post()} /></Icon>}
            </TextBoxWrapper>

            {gif && <img onClick={() => setGif("")} src={gif} alt="gif" style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '10px', borderRadius: '5px', cursor: 'pointer' }} />}

            {replyComment && replyId && <span style={{ display: 'block', fontSize: '11px', marginBottom: '5px', marginTop: '10px' }}>Replying to: </span>}
            {replyComment && replyId && <RepostBox onClick={() => setReplyId("")}>
                <RepostUser>
                    <img src={getPfp(replyComment.author.display_name ?? "", replyComment.author.pfp)} alt="" />
                    <p>{replyComment.author.display_name} • <span>@{replyComment.author.username}</span></p>
                </RepostUser>

                <RepostContent>{formatText(replyComment.comment.content)}</RepostContent>
            </RepostBox>}

            {focused && <IconsBar>

                <div>
                    <Icon disabled={posting}><HiOutlineFaceSmile onClick={() => setEmojiPicker(!emojiPicker)} title="Emoji Picker" size={20} /></Icon>
                    <Icon disabled={posting}><HiOutlineGif onClick={() => setGifPicker(!gifPicker)} title="GIF Picker" size={20} /></Icon>
                </div>

                <CharacterCount>{content.length}/500</CharacterCount>
            </IconsBar>}

            {emojiPicker && <EmojiPickerWrapper>
                <Picker theme={Theme.AUTO} onEmojiClick={(e) => {
                    const textarea = document.getElementById("msg-box") as HTMLInputElement;
                    if (textarea) textarea.value = textarea.value + e.emoji;
                    setContent(content + e.emoji);
                }} emojiStyle={EmojiStyle.NATIVE} />
            </EmojiPickerWrapper>}

            {gifPicker && <EmojiPickerWrapper>
                <div>
                    <GifPicker
                        tenorApiKey="AIzaSyARKYsMRJ9cnAcINVbWFy-Nutnql9FhcFQ"
                        onGifClick={(e) => setGif(e.url)}
                    />
                </div>
            </EmojiPickerWrapper>}
        </Box>
    )
}