import styled from 'styled-components';

//* Styles for the Post Creator Box
export const CreatorBox = styled.div`
  padding: 20px 25px;
  background: var(--navbar-color);
  border-radius: 8px;
  transition: 1s;
  box-shadow: 0px 4px 4px 0px var(--box-shadow);
  margin-bottom: 10px;
`;

export const TextBoxWrapper = styled.div`
	display: flex;
	align-items: center;

	/* svg {
		cursor: pointer;
		margin-left: 5px;
		border-radius: 20%;
		padding: 4px;

		&:hover {
			background-color: rgba(23, 114, 175, 0.1);
		}
	} */
`;

export const SmallPfp = styled.img`
	border-radius: 50%;
	object-fit: cover;
	object-fit: cover;
	width: 40px;
	height: 40px;
	cursor: pointer;
	margin-right: 15px;
`;

export const TextInput = styled.textarea`
	width: 100%;
	resize: none;
	height: 18px;
	outline: 0px;
	color: var(--text-color);
	border: 0px;
	background: none;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const IconsBar = styled.div`
	display: flex;
	max-height: 28px;
	align-items: center;
	margin-top: 10px;
	justify-content: space-between;

	div {
		display: flex;
	}
`;

export const Icon = styled.label<{ disabled?: boolean }>`
	display: block;
	margin-right: 5px;

	svg {
		color: var(--accent);
		cursor: pointer;
		border-radius: 20%;
		padding: 4px;

		&:last-child {
			margin-right: 0px;
		}

		${props => !props.disabled && `
			&:hover {
				background-color: rgba(23, 114, 175, 0.1);
			}
		`}

		${props => props.disabled && `
			color: var(--description-color);
			cursor: default;
		`}
}
`;

export const CharacterCount = styled.p`
	font-size: 12px;
	color: #8e8e8e;
	margin-top: 5px;
`;

export const RepostWrapper = styled.div`
	cursor: default;
	margin-top: 10px;
`;