import { useNavigate } from "react-router";
import { PageContainer, PageTitle } from "../styles/General";
import { LoginContainer, NoteText } from "../styles/pages/Login";
import { useContext, useState } from "react";
import { Context } from "../utils/Context";
import { BoxContainer, SettingWrapper } from "../styles/pages/Settings";
import { sendVerificationEmail } from "../api/USER";
import { HiOutlineArrowRight } from "react-icons/hi";
import { toast } from "react-toastify";

export const Settings = () => {

	const navigate = useNavigate();

	const { setUser, user, loading } = useContext(Context);

	const [sending, setSending] = useState(false);
	const sendVerifyEmail = () => {
		if (!user) return;
		if (sending) return;

		setSending(true);
		sendVerificationEmail().then(() => {
			setSending(false);
			return toast.success("Email sent!");
		}).catch((err) => {
			setSending(false);
			return toast.error(err.response.data.error);
		});
	}

	if (!user && !loading) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>Settings</PageTitle>
				{!user?.verified && <NoteText>You have not verified your email yet! <a onClick={() => sendVerifyEmail()}>Resend email {'->'}</a></NoteText>}
				<BoxContainer>
					{!user?.flags.includes("google") && <SettingWrapper onClick={() => navigate('/settings/change-email')}>
						<p>Change Email</p>
						<HiOutlineArrowRight />
					</SettingWrapper>}

					{!user?.flags.includes("google") && <SettingWrapper onClick={() => navigate('/settings/change-password')}>
						<p>Change Password</p>
						<HiOutlineArrowRight />
					</SettingWrapper>}

					<SettingWrapper onClick={() => navigate('/settings/delete-account')}>
						<p>Delete Account</p>
						<HiOutlineArrowRight />
					</SettingWrapper>

					{user?.flags.includes("google") && <p>Your account has Google login enabled.</p>}
				</BoxContainer>
			</LoginContainer>
		</PageContainer>
	);
}