import { useNavigate } from "react-router";
import { PageContainer, PageTitle } from "../../styles/General";
import { Button, Input, InputText, LoginContainer, NoteText } from "../../styles/pages/Login";
import { useContext, useState } from "react";
import { Context } from "../../utils/Context";
import { BoxContainer } from "../../styles/pages/Settings";
import { deleteAccount, updatePassword } from "../../api/USER";
import { HiOutlineEye } from "react-icons/hi";
import { hashPassword, testPassword } from "../../utils/constants";
import { toast } from "react-toastify";
import { checkVerificationCode, savePassword, sendResetEmail } from "../../api/AUTH";

export const ForgotPassword = () => {

	const navigate = useNavigate();

	const { setUser, user, loading: loadingUser } = useContext(Context);

	const [step, setStep] = useState(1);
	const [email, setEmail] = useState('');
	const [encryptedCode, setEncryptedCode] = useState('');
	const [code, setCode] = useState('');
	const [password, setPassword] = useState('');
	const [token, setToken] = useState('');

	const [loading, setLoading] = useState(false);


	const submitEmail = async () => {
		if (loading) return;
		if (!email) return;

		setLoading(true);
		sendResetEmail(email)
			.then(({ data }) => {
				toast.success("Email sent!");
				setEncryptedCode(data);
				setLoading(false);
				setStep(2);
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
				toast.error("Failed to send the email!");
			});
	}

	const validateCode = async () => {
		console.log(code, encryptedCode, loading)
		if (loading) return;
		if (!encryptedCode) return;

		setLoading(true);
		checkVerificationCode(code, encryptedCode, email)
			.then(({ data }) => {
				toast.success("Code validated!");
				setLoading(false);
				setToken(data);
				setStep(3);
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
				toast.error("Incorrect code!");
			});
	}

	const changePassword = async () => {
		if (loading) return;
		if (!password) return;

		setLoading(true);
		savePassword(password, email, code, encryptedCode, token)
			.then(({ data }) => {
				toast.success("Password updated!");
				setLoading(false);
				setStep(3);
				setUser(data);

				document.cookie = `token=${token}; expires=` + new Date(86400000 + Date.now()).toUTCString() + '; path=/;';
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
				toast.error("Failed to update the password!");
			});
	}


	// Password visibility
	function togglePw(id: string) {
		var passwordBox: any = document.getElementById(id);
		console.log(passwordBox)
		if (passwordBox.type === "password") { passwordBox.type = "text"; } else { passwordBox.type = "password"; }
	}

	if (user) navigate('/');
	return (
		<PageContainer>
			<LoginContainer>
				<PageTitle>Forgot Password</PageTitle><br />

				{step === 1 && <BoxContainer>
					<InputText>Email Adress</InputText>
					<Input onInput={(e) => setEmail(e.currentTarget.value)} id="email" placeholder="Email" type="text" />

					<Button disabled={loading} onClick={submitEmail} href="#">Send code</Button>
				</BoxContainer>}

				{step === 2 && <BoxContainer>
					<NoteText>Code has been sent to <span style={{ color: '#79abc7'}}>{email}</span></NoteText>

					<InputText>Code</InputText>
					<Input onInput={(e) => setCode(e.currentTarget.value)} id="code" placeholder="Code" type="text" />

					<Button disabled={loading} onClick={validateCode} href="#">Validate code</Button>
				</BoxContainer>}

				{step === 3 && <BoxContainer>
					<InputText>New Password</InputText>
					<div>
						<Input onInput={(e) => setPassword(e.currentTarget.value)} id="new-password" placeholder="New Password" type="password" />
						<HiOutlineEye onClick={() => togglePw("new-password")} style={{ marginLeft: '-25px', marginTop: '9px', cursor: 'pointer', position: 'absolute' }} />
					</div>

					<Button disabled={loading} onClick={changePassword} href="#">New Password</Button>
				</BoxContainer>}


			</LoginContainer>
		</PageContainer>
	);
}