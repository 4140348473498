import { useContext, useState } from "react";
import { Context } from "../../utils/Context";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { getPfp } from "../../utils/constants";
import Open from "../../assets/icons/arrow-open.svg";
import Close from "../../assets/icons/arrow-close.svg";
import { HiOutlineArrowLeftOnRectangle, HiOutlineArrowRightOnRectangle, HiOutlineBell, HiOutlineBookmark, HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineCog6Tooth, HiOutlineHome, HiOutlineUserCircle, HiOutlineUserPlus } from "react-icons/hi2";
import { FlagsRenderer } from "../root/FlagsRenderer";

const Container = styled.div`
    max-width: 325px;
    display: flex;
    height: calc(100vh - 117px);
    flex-direction: column;

    @media (max-width: 1000px) {
        display: none;
    }
`;

const TabsBox = styled.div`
    background-color: var(--navbar-color);
    border-radius: 8px;
    padding-top: 5px;
    margin-bottom: 25px;
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
`;

const TabItem = styled.div<{ active?: boolean }>`
    padding: 12.5px 18px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    font-size: 15px;


    p {
        margin: 0px;
        margin-left: 17px;
        font-weight: 400;

        span {
            background-color: var(--accent);
            color: var(--text-color);
            padding: 2px 6px;
            border-radius: 50%;
            font-size: 13px;
            margin-left: 5px;
        }
    }


    img {
        width: 25px;
        height: 25px;
        background: #d4d4d4;
    }

    ${({ active }) => active && `
        background: var(--active-color);
        border-left: 4px solid var(--accent);
        padding: 12.5px 18px 12.5px 14px;
    `}

    ${({ active }) => !active && `
        :hover {
            background-color: var(--hover-color);
        }
    `};
`;

const UserBox = styled.div`
    padding: 20px 15px 20px 25px;
    background-color: var(--navbar-color);
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
    margin-top: auto;
    margin-bottom: 25px;
`;

const UserFlex = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    img {
        cursor: pointer;
    }
`;

const UserDropdown = styled.div`
    margin-bottom: 20px;

    a {
        display: flex;
        cursor: pointer;
        color: var(--text-color);
        align-items: center;
        font-size: 15px;
        margin: 0px;
        font-weight: 500;
        margin-bottom: 12px;
        
        svg {
            margin: 0px;
            margin-right: 7.5px;
        }
    }
`;

const User = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0px 4px 4px 0px var(--box-shadow); 
        margin-right: 15px;
    }
`;

const UserName = styled.div`
    b {
        margin: 0px;
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 700;
        line-height: normal;
    }

    img {
        margin-left: 5px;
        width: 15px;
        height: 15px;
    }

    p {
        margin: 0px;
        font-size: 14px;
        font-weight: 400;
        color: var(--description-color);
    }
`;

export const Userbar = () => {

    const navigate = useNavigate();

    const { user, notifications } = useContext(Context);
    const [open, setOpen] = useState(false);

    return (
        <div style={{ maxWidth: '325px', height: 'calc(100vh - 117px)', position: 'sticky', top: '117px', overflowY: 'auto', borderRadius: '8px', marginTop: '35px' }}>
            <Container>
                <TabsBox>
                    <TabItem onClick={() => navigate('/')} active={window.location.pathname === '/'}>
                        <HiOutlineHome size={30} />
                        <p>Home</p>
                    </TabItem>

                    {user && <>
                        <TabItem active={window.location.pathname === `/notifications`} onClick={() => navigate('/notifications')}>
                            <HiOutlineBell size={30} />
                            <p>Notifications {notifications.filter((n) => n.notification.unread === true).length > 0 && <span>{notifications.filter((n) => n.notification.unread === true).length}</span>}</p>
                        </TabItem>

                        <TabItem active={window.location.pathname === `/bookmarks`} onClick={() => navigate('/bookmarks')}>
                            <HiOutlineBookmark size={30} />
                            <p>Saved posts</p>
                        </TabItem>

                        <TabItem active={window.location.pathname === `/@${user?.username}`} onClick={() => navigate(`/@${user?.username}`)}>
                            <HiOutlineUserCircle size={30} />
                            <p>Profile</p>
                        </TabItem>
                    </>}

                    {!user && <>
                        <TabItem onClick={() => navigate('/login')}>
                            <HiOutlineArrowLeftOnRectangle size={30} />
                            <p>Login</p>
                        </TabItem>
                    </>}

                    {!user && <>
                        <TabItem onClick={() => navigate('/register')}>
                            <HiOutlineUserPlus size={30} />
                            <p>Register</p>
                        </TabItem>
                    </>}
                </TabsBox>

                {user && <UserBox>
                    {open && <UserDropdown>
                        <a href="#" onClick={() => navigate(`/@${user.username}`)}><HiOutlineUserCircle size={22} /> Your Profile</a>
                        <a href="#" onClick={() => navigate('/settings')}><HiOutlineCog6Tooth size={22} /> Settings</a>
                        <a href="#" onClick={() => {
                            document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);

                        }} style={{ color: '#b40f0f' }}><HiOutlineArrowRightOnRectangle size={22} /> Logout</a>
                    </UserDropdown>}

                    <UserFlex onClick={() => setOpen(!open)}>
                        <User>
                            <img src={getPfp(user.display_name, user.pfp)} />

                            <UserName>
                                <b>{user.display_name} <FlagsRenderer flags={user.flags} /></b>
                                <p>@{user.username}</p>
                            </UserName>
                        </User>

                        {open && <HiOutlineChevronDown size={30} />}
                        {!open && <HiOutlineChevronUp size={30} />}
                    </UserFlex>
                </UserBox >}
            </Container>
        </div>
    )
}